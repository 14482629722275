import { combineReducers } from "redux";

import auth from "./auth/auth.reducer";
import chart from "./chart/chart.reducer";
import error from "./error/error.reducer";
import i18n from "./i18n/i18n.reducer";

const reducer = combineReducers({
  auth,
  chart,
  error,
  i18n,
});

export default reducer;
