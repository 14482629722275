import React from "react";
import styled from "styled-components";
import { Field } from "formik";

const Label = styled.label`
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 6px 8px;
  box-shadow: none;
  transition: all 0.1s ease-in-out;
  border-radius: 20px;
  flex: 1;
  margin: 1px;
  line-height: 19px;

  &:hover {
    cursor: pointer;
  }
`;

const Input = styled(Field)`
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;

  &:checked + ${Label} {
    background-color: black;
    color: #fff;
    box-shadow: none;
  }
`;

type ContainerProps = {
  width?: string;
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  ${(props: ContainerProps) => props.width && `width: ${props.width};`}
`;

type Props = {
  name: string;
  width?: string;
  values: Array<string>;
};

const Radio: React.FC<Props> = ({ values, name, width }) => {
  return (
    <Container role="group" aria-labelledby={`radio-${name}`} width={width}>
      {values.map((v) => (
        <React.Fragment key={`radio-${name}-${v}`}>
          <Input type="radio" name={name} value={v} id={`radio-${name}-${v}`} />
          <Label key={`radio-${name}-${v}`} htmlFor={`radio-${name}-${v}`}>
            {v}
          </Label>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default Radio;
