import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import reducer from "./reducers";
import { actions } from "./actions";

const store = createStore(reducer, applyMiddleware(thunk));

window.addEventListener("resetAuth", () => {
  console.debug("RECEIVED: resetAuth event");
  store.dispatch(actions.auth.logoutUser());
});

export default store;
