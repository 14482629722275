const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.lvmh.com/lvmh-omr/api/v1";
const CLIENT_ID = process.env.CLIENT_ID || "7aacbc6d2a954443a7a2733f7c41b14c";
const CLIENT_SECRET = process.env.CLIENT_SECRET || "941D79AEeD7747C1bc8E4Df84eCF9991";
const SELF_ID = process.env.SELF_ID || "1519812";
const SSO_URL = process.env.SSO_URL || "https://okta.lvmh.com/app/lvmh-group_lvmhholdingsomrprd_1/exkeibhzcgjRfNrvo416/sso/saml";
const GA_MEASUREMENT_ID = process.env.GA_MEASUREMENT_ID || "G-VHX1ZPR90D";

const ENV = process.env.REACT_APP_ENV || "PRD";

export { ENV, API_BASE_URL, CLIENT_ID, CLIENT_SECRET, SELF_ID, SSO_URL, GA_MEASUREMENT_ID };
