import React, { useEffect } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { useSelector } from "react-redux";

import { RootState } from "store/types";
import { ERROR_TYPES } from "utils/error.utils";

const SnackbarWrapper: React.FunctionComponent = () => {
  const error = useSelector((state: RootState) => state.error.error);
  const [openSnackbar] = useSnackbar({
    position: "bottom-right",
    style: {
      backgroundColor: "black",
      color: "white",
      borderRadius: 4,
    },
  });

  useEffect(() => {
    const { msg = "", details, type = ERROR_TYPES.INTERNAL } = error || {};
    if (type === ERROR_TYPES.API && details) {
      const { code, status, message } = details || {};
      openSnackbar(`${code}.${status} - ${message}`);
    } else if (msg) {
      openSnackbar(msg);
    } else if (error) {
      openSnackbar(error.toString());
    }
    // eslint-disable-next-line
  }, [error]);

  return <div />;
};

export default SnackbarWrapper;
