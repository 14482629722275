export const formatDate = (value: string): string => {
  const regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
  const d = new Date(value);

  // if value is date string DD/MM/YYYY
  if (regex.test(value)) {
    return value;
  }
  if (!regex.test(value) && Number.isNaN(d.getTime())) {
    // if value is not a date and if value is not a timestamp
    return "";
  }
  // if value is a timestamp
  const ye = new Intl.DateTimeFormat("fr", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("fr", { month: "2-digit" }).format(d);
  const da = new Intl.DateTimeFormat("fr", { day: "2-digit" }).format(d);

  return `${da}/${mo}/${ye}`;
};
