import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import SearchBar from "components/searchBar";
import Col from "components/surface/Col";
import Row from "components/surface/Row";
import colors from "assets/style/colors";

import logo from "assets/images/logo_LVMH.svg";
import { spacing } from "assets/style/spacing";

const Container = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${colors.lightGrey};
  padding: 12px 24px;
`;

const Title = styled.span`
  font-size: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 768px) {
    margin-right: ${spacing(18)}px;
  }
`;

const Brand = styled.img`
  height: 30px;
  padding-top: 6px;
  font-family: LVBold;
`;

const MaxWidth = styled.div`
  max-width: 1700px;
  width: 100%;
  display: flex;
`;

const Header: React.FunctionComponent = () => {
  return (
    <Container>
      <MaxWidth>
        <Row fullWidth margin="0" justify="center">
          <Col padding="12px 0">
            <Title>
              <Brand src={logo} alt="logo" />
              <FormattedMessage id="home.title" />
            </Title>
          </Col>
          <Col xs={12} md padding="12px 0">
            <SearchBar />
          </Col>
        </Row>
      </MaxWidth>
    </Container>
  );
};

export default Header;
