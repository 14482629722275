import styled from "styled-components";
import { Field } from "formik";

const Input = styled(Field)`
  font-size: 16px;
  border: 1px solid #c4c4c4;
  padding: 3px 6px;
  flex: 1;
`;

export default Input;
