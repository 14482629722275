import styled from "styled-components";

type InputLabelProps = {
  minWidth?: string;
  margin?: string;
  padding?: string;
  textAlign?: string;
};

const InputLabel = styled.label`
  font-family: LVDemi;
  font-size: 18px;
  font-weight: 400;
  ${(props: InputLabelProps) => props.minWidth && `min-width: ${props.minWidth};`}
  ${(props: InputLabelProps) => props.margin && `margin: ${props.margin};`}
  ${(props: InputLabelProps) => (props.padding ? `padding: ${props.padding};` : "padding-bottom: 4px;")}
  text-align:${(props: InputLabelProps) => (props.textAlign ? props.textAlign : "left")};
`;

export default InputLabel;
