import { NextStep, Successor, User, UserUpdateForm } from "types/common.types";
import { READINESS } from "utils/form.utils";
import { MAX_CHILDREN } from "components/organigram/lib/js/organigram.constants";
import { ActionTypes, ChartState, ChartActionTypes } from "./chart.types";

const updateUser: any = (orgChart: User, user: UserUpdateForm) => {
  const { id, children = [], ...rest } = orgChart;
  const { children: newChildren = [] } = user;

  let updatedChildren = [...children];

  let result = { id, ...rest };

  if (id === user.id) {
    result = { ...result, ...user };
    updatedChildren = newChildren;
  }

  return {
    ...result,
    children: updatedChildren.map((c) => updateUser(c, user)),
  };
};

const getSubTree: any = (orgChart: User, userId: string) => {
  const { id, children = [] } = orgChart;

  if (id === userId) {
    return orgChart;
  }

  let result = null;
  for (let i = 0; i < children.length; i += 1) {
    result = getSubTree(children[i], userId);
    if (result) {
      break;
    }
  }

  return result;
};

const expandFirstChildren = (chart: User): User => {
  const result = { ...chart, expanded: true };
  if (chart.children) {
    result.children = result.children.map((c: User) => ({ ...c, expanded: true }));
  }
  return result;
};

const orderListByReadiness = (list: Array<Successor | NextStep>): any => {
  list.sort((a, b) => {
    const readinessWeight = {
      [READINESS.YEAR]: 1,
      [READINESS.MID]: 2,
      [READINESS.LONG]: 3,
    };

    return (readinessWeight[a.readiness] || 4) - (readinessWeight[b.readiness] || 4);
  });

  return list;
};

const orderByReadiness = (orgChart: User): any => {
  const { potentialSuccessor, children = [], employees = [], displayTeamOmrInfo, displaySuccessors, hideTeamPhoto, ...rest } = orgChart;
  return {
    ...rest,
    hideTeamPhoto: !!hideTeamPhoto,
    displayTeamOmrInfo: displayTeamOmrInfo === undefined ? true : displayTeamOmrInfo,
    displaySuccessors: displaySuccessors === undefined ? true : displaySuccessors,
    employees: employees.map((e) => ({
      ...e,
      nextSteps: e.nextSteps ? orderListByReadiness(e.nextSteps) : [],
      hidePhoto: !!e.hidePhoto,
      displayOmrInfo: e.displayOmrInfo === undefined ? true : !!e.displayOmrInfo,
      displayNextSteps: e.displayNextSteps === undefined ? true : !!e.displayNextSteps,
    })),
    potentialSuccessor: potentialSuccessor ? orderListByReadiness(potentialSuccessor) : undefined,
    children: children ? children.map((c) => orderByReadiness(c)) : [],
  };
};

// Browse the tree and set expanded = false if depth > limit
const limitDepth = (orgChart: User, limit: number, current: number): any => {
  const { children = [], expanded, ...rest } = orgChart;

  return {
    ...rest,
    expanded: current > limit ? false : expanded,
    children: children ? children.map((c) => limitDepth(c, limit, current + 1)) : [],
  };
};

// Browse the tree and set expanded = false if siblings > max siblings
const limitChildren = (orgChart: User, limit: number, siblings: number): any => {
  const { children = [], ...rest } = orgChart;

  return {
    ...rest,
    children: children
      ? children.map((c) => ({
          ...limitChildren(c, limit, children.length),
          expanded: siblings > limit ? false : c.expanded,
        }))
      : [],
  };
};

const chartReducer = (state: ChartState = { searchBar: "" }, action: ChartActionTypes): ChartState => {
  switch (action.type) {
    case ActionTypes.UPDATE_CHART: {
      return {
        ...state,
        userToEdit: undefined,
        orgChart: action.payload ? expandFirstChildren(orderByReadiness(action.payload)) : undefined,
      };
    }
    case ActionTypes.REMOVE_CHART:
      return {
        ...state,
        orgChart: undefined,
      };
    case ActionTypes.LOADING_CHART:
      return {
        ...state,
        loadingChart: action.payload,
      };
    case ActionTypes.SELECT_USER_EDIT:
      return {
        ...state,
        userToEdit: action.payload,
      };
    case ActionTypes.SET_ROOT_USER:
      return {
        ...state,
        rootUser: action.payload,
      };
    case ActionTypes.SET_SEARCH_BAR:
      return {
        ...state,
        searchBar: action.payload,
      };
    case ActionTypes.UPDATE_USER:
      return {
        ...state,
        userToEdit: action.payload.hideForm ? undefined : action.payload.user,
        orgChart: orderByReadiness(updateUser(state.orgChart, action.payload.user)),
      };
    case ActionTypes.EXPAND_MANAGER: {
      const newChart = {
        ...action.payload,
        expanded: true,
        children: [state.orgChart],
      };

      return {
        ...state,
        userToEdit: undefined,
        orgChart: limitChildren(limitDepth(orderByReadiness(newChart), 3, 1), MAX_CHILDREN, 1),
      };
    }
    case ActionTypes.SHRINK_MANAGER: {
      const { id } = action.payload;
      return {
        ...state,
        userToEdit: undefined,
        orgChart: orderByReadiness(getSubTree(state.orgChart, id)),
      };
    }
    default:
      return state;
  }
};

export default chartReducer;
