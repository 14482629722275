import { AuthActionTypes, ACTION_TYPES } from "./auth.types";

export const loginUser = (): AuthActionTypes => {
  return {
    type: ACTION_TYPES.LOGIN_USER,
  };
};

export const logoutUser = (): AuthActionTypes => {
  return {
    type: ACTION_TYPES.LOGOUT_USER,
  };
};
