import React from "react";
import styled from "styled-components";

type InputProps = {
  variant?: string;
  fontSize?: number;
  flex?: string;
  padding?: string;
  renderIcon?: () => any;
};

const Input = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props: InputProps) => props.variant === "black" && "color: white; background-color: black; border: none;"}
  ${(props: InputProps) => props.variant === "white" && "color: black; background-color: white; border: none;"}
  ${(props: InputProps) => props.fontSize && `font-size: ${props.fontSize}px;`}
  ${(props: InputProps) => props.renderIcon && "line-height: 0px;"}
  ${(props: InputProps) => props.flex && `flex: ${props.flex}px;`}
  padding: ${(props: InputProps) => props.padding && props.padding};
`;

const IconContainer = styled.span`
  padding-right: 3px;
`;

type Props = {
  value?: string;
  type: "button" | "submit" | "reset";
  variant?: "black" | "white";
  fontSize?: number;
  flex?: string;
  padding?: string;
  renderIcon?: () => any;
  onClick?: () => unknown;
};

const Button: React.FC<Props> = ({ flex, value, padding = "12px", renderIcon, onClick, fontSize, type = "button", variant, ...props }) => {
  return (
    <Input
      type={type}
      onClick={onClick}
      value={value}
      flex={flex}
      padding={padding}
      variant={variant}
      fontSize={fontSize}
      renderIcon={renderIcon}
      {...props}
    >
      {renderIcon && <IconContainer>{renderIcon()}</IconContainer>} {value}
    </Input>
  );
};

export default Button;
