import axios from "axios";

import { LOCAL_STORAGE } from "utils/auth.utils";
import * as ENV from "config/env";
import endpoints from "./endpoints";

axios.defaults.baseURL = ENV.API_BASE_URL;
// 5 minutes
axios.defaults.timeout = 60000 * 5;

axios.defaults.headers.client_id = "token";
axios.defaults.headers.client_secret = "token";

const ws = axios.create({ maxRedirects: 0 });
let refreshDate = Date.now();

const makeRequestCreator = () => {
  let token: any;

  // eslint-disable-next-line consistent-return
  return (query: any): any => {
    // Check if we made a request
    if (token) {
      // Cancel the previous request before making a new request
      token.cancel();
    }
    // Create a new CancelToken
    token = axios.CancelToken.source();

    return ws({ ...query, cancelToken: token.token });
  };
};
const search = makeRequestCreator();

const api = {
  search: {
    byTerm: async (query: string): Promise<any> => {
      return search({
        method: "post",
        url: endpoints.search.byTerm,
        data: {
          text: query,
          index: 0,
        },
      });
      // return ws.post(endpoints.search.byTerm, { text: query, index: 0 });
    },
  },
  chart: {
    getChart: (id: string) => {
      return ws.get(endpoints.positions.byId(id), {
        data: null,
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    refresh: (ids: string) => {
      return ws.post(endpoints.positions.refresh, null, {
        params: { list: ids },
      });
    },
  },
  authentication: {
    validate: (refresh: boolean) => {
      refreshDate = Date.now();
      return ws.get(endpoints.authentication.validate, {
        params: { newToken: refresh },
      });
    },
  },
};

export const refreshToken = async () => {
  const { data } = await api.authentication.validate(true);
  if (data.token) {
    const apiToken = `Bearer ${data.token}`;
    localStorage.setItem(LOCAL_STORAGE.API_TOKEN, apiToken);
  }
};

ws.interceptors.request.use(async (request) => {
  ws.defaults.headers.common = {
    client_id: ENV.CLIENT_ID,
    client_secret: ENV.CLIENT_SECRET,
  };

  if (request.headers) {
    request.headers.client_id = ENV.CLIENT_ID;
    request.headers.client_secret = ENV.CLIENT_SECRET;
    request.headers.selfId = ENV.SELF_ID;
    request.headers.Authorization = localStorage.getItem(LOCAL_STORAGE.API_TOKEN) || "";
  }

  // If need to refresh token
  if (Date.now() - refreshDate > 1000 * 60 * 30) {
    refreshToken();
  }

  return request;
});

ws.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error: any) => {
    if (axios.isCancel(error)) {
      return;
    }

    if (
      error.response &&
      ((error.response.data && error.response.data.status && error.response.data.status === 401) ||
        (error.response.status && error.response.status === 401))
    ) {
      /* If not logged, save current uri and reset apiToken */
      localStorage.setItem(LOCAL_STORAGE.REDIRECT_URL, window.location.pathname);
      localStorage.removeItem(LOCAL_STORAGE.API_TOKEN);

      window.dispatchEvent(new CustomEvent("resetAuth"));
    }

    throw error;
  }
);

export default api;
