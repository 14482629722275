import { TALENT_STATUS, KEY_POSITION, RETENTION_RISK } from "../../../../utils/form.utils";
import { COLORS, defaultImage } from "./organigram.constants";
import { formatDate } from "../../../../utils/date.utils";

const MAX_CHAR_PER_LINE = 60;
const MAX_CHAR_PER_LINE_TITLE = 50;

export function isOMRAllowed(d) {
  if (
    d.keyPosition === undefined &&
    d.potentialSuccessor === undefined &&
    d.employees &&
    d.employees[0] &&
    (d.employees[0].nextSteps === undefined || (d.employees[0].nextSteps && d.employees[0].nextSteps.length === 0))
  ) {
    return false;
  }
  return true;
}

const computeCharLen = (strings) => {
  let result = 0;

  if (!strings || strings.length === 0) {
    return result;
  }
  strings.forEach((s) => {
    if (!s || s.length === 0) {
      return 0;
    }
    for (let i = 0; i < s.length; i += 1) {
      const c = s.charAt(i);
      if (c === " " || c === "" || c === "&" || c === "(" || c === ")") {
        result += 1;
      } else if (c === c.toUpperCase()) {
        result += 1.5;
      } else if (c === c.toLowerCase()) {
        result += 1;
      }
    }
    return 1;
  });

  return result;
};

export function computeHeight(template, d) {
  // const isGroupKeyPosition = d.keyPosition === KEY_POSITION.GROUP;
  // const isGroupKeyContributor = d.keyContributor === TALENT_STATUS.GROUP;
  // const isCompanyKeyPosition = d.keyPosition === KEY_POSITION.COMPANY;
  // const isCompanyKeyContributor = d.keyContributor === TALENT_STATUS.COMPANY;
  let height = 0;

  let nextStepsHeight = 0;
  let titleHeight = 0;

  const employees = d.employees && d.employees.length ? d.employees : [{}];
  const totalEmployees = employees.length;
  const totalEmployeesDisplayingOmr = employees.filter((e) => e.displayOmrInfo !== false).length;

  if (!isOMRAllowed(d) || d.displayOmrInfo === false) {
    template = "simple";
  }

  // eslint-disable-next-line no-return-assign
  employees.forEach((e) => {
    if (d.title) {
      titleHeight += Math.ceil(computeCharLen([d.title]) / MAX_CHAR_PER_LINE_TITLE) * 30;
    }

    if (e.firstName || e.lastName) {
      titleHeight += Math.ceil(computeCharLen([e.firstName, e.lastName]) / MAX_CHAR_PER_LINE_TITLE) * 30;
    } else {
      titleHeight += 30;
    }

    if (!(e.displayOmrInfo === false || d.displayOmrInfo === false)) {
      if (template !== "simple") {
        titleHeight +=
          Math.ceil(
            computeCharLen([e.performance, e.potential, e.retentionRisk, e.talentStatus, "Perf:-Ret.Risk:-Pot.:-Talent Status:"]) /
              MAX_CHAR_PER_LINE
          ) * 25;
      }

      if (e.nextSteps && e.nextSteps.length && e.displayNextSteps === true) {
        // eslint-disable-next-line no-return-assign
        e.nextSteps.forEach(
          // eslint-disable-next-line no-return-assign
          (n) => (nextStepsHeight += Math.ceil(computeCharLen([n.jobTitle, n.maison || "(N/A)"]) / MAX_CHAR_PER_LINE) * 27)
        );
      }
    }
  });

  let topBlock = 0;
  let successorsBlock = 0;
  let nextStepsBlock = 0;

  // Base height
  height = 45 * totalEmployees + totalEmployees * 4 + titleHeight;

  switch (template) {
    case "simple":
      break;
    case "omr":
    case "full":
      topBlock = 54 * totalEmployees + titleHeight;
      if (totalEmployeesDisplayingOmr > 0) {
        // next steps padding + border-top
        nextStepsBlock = 14;
        if (d.displaySuccessors === true) {
          // successors padding
          successorsBlock = 12;
          if (d.potentialSuccessor && d.potentialSuccessor.length > 0) {
            // eslint-disable-next-line no-return-assign
            d.potentialSuccessor.forEach(
              // eslint-disable-next-line no-return-assign
              (n) =>
                (successorsBlock += n.employee
                  ? Math.ceil(
                      computeCharLen([n.employee.firstName, n.employee.lastName, n.employee.maison || "(N/A)"]) / MAX_CHAR_PER_LINE
                    ) * 27
                  : 27)
            );
          } else {
            // successors min height
            successorsBlock += 20;
          }
        }
        if (template === "full" && nextStepsHeight) {
          nextStepsBlock += nextStepsHeight;
        }
        height = successorsBlock * totalEmployeesDisplayingOmr + nextStepsBlock + topBlock;
      }
      break;
    case "next":
      topBlock = 54 * totalEmployees + titleHeight;
      if (totalEmployeesDisplayingOmr > 0) {
        // next steps padding + border-top
        nextStepsBlock = 14;
        if (nextStepsHeight) {
          nextStepsBlock += nextStepsHeight;
        }
        height = nextStepsBlock + topBlock;
      }
      break;
    default:
      height = 100;
      break;
  }

  if (d.children && d.children.length > 0) {
    height += 16;
  }
  return height;
}

export function formatName(firstName, lastName) {
  return firstName || lastName ? `${firstName || ""} ${lastName || ""}`.trim() : "Vacant";
}

export function printString(s = "") {
  return s;
}

export function renderNode(template, d, titleMarginLeft, borderRadius, readinessColor) {
  /* We don't use CSS because of broken image export */
  const paddingFirstBlock = `padding: 2px 4px 4px ${titleMarginLeft - 6}px; margin: 2px 6px 6px 6px; position: relative;`;
  const nodeCommonStyle = `outline: 1px solid #A9A9A9; margin:0; font-size:18px; color: black; min-height: 100%; box-sizing: border-box;`;
  const imgCommonStyle = `box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); position: absolute; width: 100px; height: 100px; left: -50px;object-fit: cover; border-radius: 16px; object-position: top;`;
  let node = "";
  const printEmployees = Array.isArray(d.employees) && d.employees.length > 0 ? d.employees : [{}];
  const nameStyle = "margin-top: 5px; font-size:22px; font-weight: bold; white-space: break-spaces; word-break: break-word;";
  const titleStyle = "font-size: 22px; font-weight: bold; white-space: break-spaces; word-break: break-word;";
  const futurAStyle =
    "outline: 6px dashed #eebc1e; outline-offset: 0px; position: absolute; width: 106%; margin-left: -3%; height: 110%; left: 0; top: -5%;";
  const careerAspirations = `<span class="career-aspirations" style="position: absolute; top: 0px; font-size: 30px; right: 10px;">➝</span>`;

  if (!isOMRAllowed(d) || d.displayOmrInfo === false) {
    template = "simple";
  }

  switch (template) {
    case "simple":
      node += `<div style="${nodeCommonStyle}">`;
      printEmployees.forEach((e, i) => {
        node += `<div style="${i > 0 ? "border-top: 1px solid #A9A9A9; margin-top: 22px;" : "padding-top: 2px;"} min-height: 97px;">`;
        node += `<div style="${paddingFirstBlock}">
                        <div style="${nameStyle}">${formatName(e.firstName, e.lastName)}</div>
                        <div style="${titleStyle}">${d.title}</div>
                        <div style="margin-top:5px;">POS Date: ${formatDate(e.jobTenure) || "N/A"} - Group Start Date: ${
          formatDate(e.groupStartDate) || "N/A"
        }</div>
                        <img src="data:image/jpeg;charset=utf-8;base64,${
                          e.hidePhoto || d.hidePhoto ? defaultImage : e.photo || defaultImage
                        }" style="${imgCommonStyle} top: ${d.employees.length > 1 ? 0 : -40}px; "/>
                    </div>
                    </div>
                        `;
      });
      node += `</div>`;
      break;
    case "omr":
    case "full":
      node += `<div style="${nodeCommonStyle}">`;
      printEmployees.forEach((e, i) => {
        d.isGroupKeyPosition = d.keyPosition === KEY_POSITION.GROUP && !(e.displayOmrInfo === false || d.displayOmrInfo === false);
        d.isCompanyKeyPosition = d.keyPosition === KEY_POSITION.COMPANY && !(e.displayOmrInfo === false || d.displayOmrInfo === false);

        // Add border if key Position
        d._borderPosition = d.isGroupKeyPosition
          ? `outline-offset: -2px; outline: 4px solid red; border-radius: ${borderRadius}px ${borderRadius}px 0 0;`
          : d.isCompanyKeyPosition
          ? `outline-offset: -2px; outline: 4px solid blue; border-radius: ${borderRadius}px ${borderRadius}px 0 0;`
          : "";

        e.isGroupTalentStatus = e.talentStatus === TALENT_STATUS.GROUP && !(e.displayOmrInfo === false || d.displayOmrInfo === false);
        e.isCompanyTalentStatus = e.talentStatus === TALENT_STATUS.COMPANY && !(e.displayOmrInfo === false || d.displayOmrInfo === false);

        e._colorName = e.isGroupTalentStatus ? "red" : e.isCompanyTalentStatus ? "blue" : "black";

        e._highRetentionText =
          e.retentionRisk === RETENTION_RISK.HIGH && !(e.displayOmrInfo === false || d.displayOmrInfo === false)
            ? '<span style="color: red; font-weight: bold; font-family: Georgia, sans-serif; font-size: 36px; line-height: 26px; position: absolute; margin-left: 4px;">!</span>'
            : "";

        e._criticalExpertText =
          e.criticalExpert && !(e.displayOmrInfo === false || d.displayOmrInfo === false)
            ? `<span style="color: #0929a8; line-height: 42px; font-weight: bold; font-family: LVRegular; font-size: 54px; position: absolute; margin-left: ${
                e._highRetentionText ? "14" : "4"
              }px;">*</span>`
            : "";

        e._careerAspirations =
          (e.functionalCareerAspiration?.length || e.geographicalCareerAspiration?.length) &&
          !(e.displayOmrInfo === false || d.displayOmrInfo === false)
            ? careerAspirations
            : "";

        node += `<div style="${i > 0 ? `border-top: 2px solid ${COLORS.GREY}; margin-top: 12px;` : "padding-top: 2px;"} min-height: 110px;">
                    <div style="${paddingFirstBlock} ${d._borderPosition}">
                    ${
                      (e.futurA && !(d.displayOmrInfo === false || e.displayOmrInfo === false) && `<div style="${futurAStyle}"> </div>`) ||
                      ""
                    }
                        <div style="${nameStyle} color: ${e._colorName};">${formatName(e.firstName, e.lastName)} ${e._highRetentionText} ${
          e._criticalExpertText
        } ${e._careerAspirations}</div>
                        <div style="${titleStyle}">${d.title}</div>
                        <div style="margin-top:5px; position: relative;">POS Date: ${
                          formatDate(e.jobTenure) || "N/A"
                        } - Group Start Date: ${formatDate(e.groupStartDate) || "N/A"}</div>
                        ${
                          e.displayOmrInfo === false || d.displayOmrInfo === false
                            ? ""
                            : `<div style="margin-top:5px;">Perf: ${e.performance ? e.performance : "N/A"} - Ret. Risk: ${
                                e.retentionRisk ? e.retentionRisk : "N/A"
                              } - Pot.: ${e.potential ? e.potential : "N/A"} - Talent Status: ${
                                e.talentStatus ? e.talentStatus : "N/A"
                              }</div>`
                        }
                        <img src="data:image/jpeg;charset=utf-8;base64,${
                          e.hidePhoto || d.hidePhoto ? defaultImage : e.photo || defaultImage
                        }" style="box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); position: absolute; width: 100px; height: 100px; left: -50px; top: ${
          d.employees.length > 1 ? 0 : -40
        }px; object-fit: cover; border-radius: 16px; object-position: top;"/>
                    </div>`;

        // Potential successors
        if (!(d.displaySuccessors === false || e.displayOmrInfo === false || d.displayOmrInfo === false)) {
          node += `<div style="padding: 6px 6px 6px 26px; font-size: 20px; background: ${COLORS.GREY}; min-height: 20px;"><ul>`;
          if (d.potentialSuccessor) {
            d.potentialSuccessor.forEach((ps) => {
              const color = readinessColor[ps.readiness] || readinessColor["Long-term (+ 2 Y)"];
              node += `<li style="white-space: break-spaces; word-break: break-word; color: ${color}">${
                ps.employee && ps.employee.firstName ? ps.employee.firstName : ""
              } ${ps.employee && ps.employee.lastName ? ps.employee.lastName : ""} (${(ps.employee && ps.employee.maison) || "N/A"})</li>`;
            });
          }
          node += `</ul></div>`;
        }

        // Next steps
        if (template === "full" && !(e.displayNextSteps === false || e.displayOmrInfo === false || d.displayOmrInfo === false)) {
          if (e.nextSteps) {
            node += `<div style="${e.nextSteps.length > 0 ? "padding: 6px 6px 6px 26px;" : ""} font-size: 20px;"><ul>`;
            e.nextSteps.forEach((ns) => {
              const color = readinessColor[ns.readiness] || readinessColor["Long-term (+ 2 Y)"];
              node += `<li style="white-space: break-spaces; word-break: break-word; color: ${color}">${ns.jobTitle || "N/A"} (${
                ns.maison || "N/A"
              })</li>`;
            });
          }
          node += ` </ul></div> `;
        }
        node += `</div>`;
      });
      break;

    case "next":
      node += `<div style="${nodeCommonStyle}">`;
      printEmployees.forEach((e, i) => {
        e._careerAspirations =
          (e.functionalCareerAspiration?.length || e.geographicalCareerAspiration?.length) &&
          !(e.displayOmrInfo === false || d.displayOmrInfo === false)
            ? careerAspirations
            : "";

        node += `<div style="${i > 0 ? `border-top: 2px solid ${COLORS.GREY};` : "padding-top: 2px;"} min-height: 110px;">`;
        node += `<div style="${paddingFirstBlock}">
                        <div style="${nameStyle}">${formatName(e.firstName, e.lastName)} ${e._careerAspirations}</div>
                        <div style="${titleStyle}">${d.title}</div>
                        <div style="margin-top:5px;">POS Date: ${formatDate(e.jobTenure) || "N/A"} - Group Start Date: ${
          formatDate(e.groupStartDate) || "N/A"
        }</div>
                        ${
                          e.displayOmrInfo === false || d.displayOmrInfo === false
                            ? ""
                            : `<div style="margin-top:5px;">Perf: ${e.performance ? e.performance : "N/A"} - Ret. Risk: ${
                                e.retentionRisk ? e.retentionRisk : "N/A"
                              } - Pot.: ${e.potential ? e.potential : "N/A"} - Talent Status: ${
                                e.talentStatus ? e.talentStatus : "N/A"
                              }</div>`
                        }
                        <img src="data:image/jpeg;charset=utf-8;base64,${
                          e.hidePhoto || d.hidePhoto ? defaultImage : e.photo || defaultImage
                        }" style="${imgCommonStyle} top: ${d.employees.length > 1 ? 0 : -40}px; "/>
                    </div>`;

        if (e.displayNextSteps === true)
          node += `<div style="${
            e.nextSteps && e.nextSteps.length > 0 && !(e.displayOmrInfo === false || d.displayOmrInfo === false)
              ? `border-top: 2px solid ${COLORS.GREY};`
              : ""
          } padding: 6px 6px 6px 26px; font-size: 20px;"><ul>`;

        if (
          e.nextSteps &&
          e.nextSteps.length > 0 &&
          !(e.displayNextSteps === false || e.displayOmrInfo === false || d.displayOmrInfo === false)
        ) {
          e.nextSteps.forEach((ns) => {
            const color = readinessColor[ns.readiness] || readinessColor["Long-term (+ 2 Y)"];
            node += `<li style="white-space: break-spaces; word-break: break-word; color: ${color}">${ns.jobTitle || "N/A"} (${
              ns.maison || "N/A"
            })</li>`;
          });
        }
        node += ` </ul></div> `;

        node += `</div>`;
      });
      node += `</div>`;

      break;
    default:
      node = `UNKNOWN TEMPLATE : ${template}`;
      break;
  }
  node += `</div>`;
  return node;
}

export function renderLegend(template, legendDiv, omrLegendWidth, nextLegendWidth) {
  switch (template) {
    case "full":
    case "omr":
      legendDiv.html(`<div class="legend" style="text-align: center; font-size: 18px; font-weight: bold; text-transform: capitalize;">
<table width="${omrLegendWidth}"  style="text-align: left;">
  <tr>
    <th style="padding: 0px 10px 0px 40px;text-transform: uppercase;">position</th>
    <th style="padding: 0px 10px 0px 40px;text-transform: uppercase;">contribution level</th>
    <th style="padding: 0px 10px 0px 40px;text-transform: uppercase;">specific talent label</th>
    <th style="padding: 0px 10px 0px 40px;text-transform: uppercase;">Readiness ${template === "omr" ? "for successor" : ""}</th>
    <th style="padding: 0px 10px 0px 40px;text-transform: uppercase;">others</th>
  </tr>
  <tr>
    <td style="padding: 10px 10px 10px 20px;">
      <div class="cell" style="display: inline-flex;">
        <div class="group-key-position" style="padding-left: 10px; padding-right: 10px; text-align: center;">title</div><span class="label" style="margin-left: 20px;">group key position</span>
      </div>
    </td>
    <td style="padding: 10px 10px 10px 20px;">
      <div class="cell" style="display: inline-flex;">
        <div class="group-key-contributor">name surname</div><span class="label" style="margin-left: 20px;">group key contributor</span>
      </div>
    </td>
    <td style="padding: 10px 10px 10px 20px;">
      <div class="cell" style="display: inline-flex;">
        <div class="futur-a" style="padding-left: 10px; padding-right: 10px; text-align: center;">title</div><span class="label" style="margin-left: 20px;">FuturA</span>
      </div>
    </td>
    <td rowspan="2" style="padding: 10px 10px 10px 20px;">
      <div class="cell" style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;">
        <span class="label" style="margin-left: 20px; color: ${COLORS.GREEN}">Ready within a year</span>
        <span class="label" style="margin-left: 20px; color: ${COLORS.ORANGE}">Mid-Term (1 to 2 years)</span>
        <span class="label" style="margin-left: 20px; color: ${COLORS.PURPLE}">Long-Term (+2 years)</span>
      </div>
    </td>
    <td style="padding: 10px 10px 10px 20px;">
      <div class="cell" style="display: inline-flex;">
        <span class="high-retention-risk" style="line-height: 20px; min-width: 24px; display:flex; justify-content: flex-end;">!</span><span class="label" style="margin-left: 10px;">High Retention Risk</span>
      </div>
    </td>
  </tr>
  <tr>
    <td style="padding: 10px 10px 10px 20px;">
      <div class="cell" style="display: inline-flex;">
        <div class="company-key-position" style="padding-left: 10px; padding-right: 10px; text-align: center;">title</div><span class="label" style="margin-left: 20px;">company key position</span>
      </div>
    </td>
    <td style="padding: 10px 10px 10px 20px;">
      <div class="cell" style="display: inline-flex;">
        <div class="company-key-contributor">name surname</div><span class="label" style="margin-left: 20px;">company key contributor</span>
      </div>
    </td>
     <td style="padding: 10px 10px 10px 20px;">
        <div class="cell" style="display: inline-flex;">
          <span style="padding-left: 10px; padding-right: 10px; text-align: center; min-width: 28px;">&nbsp<span class="critical-expert" style="font-size: 42px; line-height: 34px; position: absolute; font-family: LVRegular; color: #0929a8;">*</span></span>
        </div>
        <span class="label" style="margin-left: 16px;">critical expert</span>
      </div>
    </td>
    <td style="padding: 10px 10px 10px 20px; display: flex;">
      <div class="cell" style="display: inline-flex;">
        <span class="career-aspirations" style="text-align: center; min-width: 30px;">➝</span>
      </div>
      <span class="label" style="margin-left: 4px;">Career aspirations</span>
    </td>
    <td style="padding: 10px 10px 10px 20px;">
      <div class="cell" style="display: inline-flex;"></div>
    </td>
  </tr>
</table>
</div>`);
      break;
    case "next":
      legendDiv.html(`<div class="legend" style="text-align: center; font-size: 18px; font-weight: bold; text-transform: capitalize;">
<table width="${nextLegendWidth}" style="text-align: left;">
  <tr>
    <th style="padding: 0px 10px 0px 40px;text-transform: uppercase;">readiness for next step</th>
    <th style="padding: 0px 10px 0px 40px;text-transform: uppercase;">others</th>
  </tr>
  <tr>
    <td style="padding: 10px 10px 10px 20px;">
      <div class="cell" style="display: inline-flex;">
        <span class="label" style="margin-left: 20px; color: ${COLORS.GREEN}">Ready within a year</span>
      </div>
    </td>
    <td style="padding: 10px 10px 10px 20px; display: flex;">
      <div class="cell" style="display: inline-flex;">
        <span class="career-aspirations" style="text-align: center; min-width: 30px;">➝</span>
      </div>
      <span class="label" style="margin-left: 4px;">Career aspirations</span>
    </td>
  </tr>
  <tr>
    <td style="padding: 0 10px 10px 20px;">
      <div class="cell" style="display: inline-flex;">
        <span class="label" style="margin-left: 20px; color: ${COLORS.ORANGE}">Mid-Term (1 to 2 years)</span>
      </div>
    </td>
  </tr>
  <tr>
    <td style="padding: 0 10px 10px 20px;">
      <div class="cell" style="display: inline-flex;">
        <span class="label" style="margin-left: 20px; color: ${COLORS.PURPLE}">Long-Term (+2 years)</span>
      </div>
    </td>
  </tr>
  </table>
</div>`);
      break;
    default:
      legendDiv.html(``);
      break;
  }
}
