import React from "react";
import styled from "styled-components";
import { Col as FlexCol } from "react-flexbox-grid";

import { spacing } from "assets/style/spacing";

type ColProps = {
  justify?: string;
  align?: string;
  padding?: string;
  fullWidth?: boolean;
  flex?: boolean;
};

const Col = styled(({ fullWidth, align, flex, justify, ...props }) => <FlexCol {...props} />)`
  ${(props: ColProps) => props.justify && `justify-content: ${props.justify};`}
  ${(props: ColProps) => props.padding && `padding: ${props.padding};`}
  ${(props: ColProps) => props.align && `align-items: ${props.align};`}
  ${(props: ColProps) => props.flex && `display: flex;`}
  ${(props: ColProps) => !props.padding && `padding-left: ${spacing(2)}px;`}
  ${(props: ColProps) => !props.padding && `padding-right: ${spacing(2)}px;`}
`;

export default Col;
