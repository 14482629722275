import * as auth from "./auth/auth.actions";
import * as chart from "./chart/chart.actions";
import * as error from "./error/error.actions";
import * as i18n from "./i18n/i18n.actions";

export const actions = {
  auth,
  chart,
  error,
  i18n,
};

export default actions;
