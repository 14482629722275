import React from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { FieldArray, useFormikContext } from "formik";
import { DragDropContext, Draggable as Drag, Droppable } from "react-beautiful-dnd";
import { Move, Trash2 } from "react-feather";

import actions from "store/actions";
import { FORM_TYPES } from "utils/form.utils";
import SectionTitle from "components/editPanel/ui/SectionTitle";
import SwitchWithLabel from "components/switchWithLabel/SwitchWithLabel";
import { User } from "types/common.types";
import { spacing } from "assets/style/spacing";
import colors from "assets/style/colors";
import Row from "./ui/Row";
import InputLabel from "./ui/InputLabel";
import { renderButtonSubForm } from "./form.config";
import LabelGroup from "./ui/LabelGroup";
import Input from "./ui/Input";

const Container = styled.div`
  margin-bottom: 12px;
  position: relative;
`;

interface ItemProps {
  isDragging: boolean;
}

const Item = styled.div`
  cursor: default;
  & > * {
    cursor: default;
  }
  background: white;
  border: 1px solid ${colors.borderGrey};
  margin: 0 0 ${spacing(2)}px 0;
  display: flex;
  position: relative;
`;

const Badge = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: black;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -12px;
`;

const BadgeText = styled.span`
  margin-top: -2px;
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${spacing(4)}px ${spacing(2)}px ${spacing(4)}px ${spacing(6)}px;
`;

const Right = styled.div`
  display: flex;
  width: 30px;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  cursor: grab;
  background: ${colors.borderGrey};
  background: ${(props: ItemProps) => (props.isDragging ? colors.hoverOverlay : colors.borderGrey)};
  &:hover {
    background: ${colors.hoverOverlay};
  }
`;

const CompanyInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface DragContainerProps {
  isDraggingOver: boolean;
}

const DragContainer = styled.div`
  width: 100%;
  padding: ${spacing(2)}px 0 0 0;
  background: ${(props: DragContainerProps) => (props.isDraggingOver ? "white" : "none")};
`;

const Draggable = styled(Drag)`
  cursor: default;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchContainer = styled.div`
  display: flex;
  flex: 1;
`;

const TrashContainer = styled.div`
  cursor: pointer;
  margin-left: 24px;
`;

type Props = {
  values: any;
  selectedUser: User;
};

const TeamForm: React.FC<Props> = ({ values, selectedUser }) => {
  const intl = useIntl();
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const updateUser = (user: any) => dispatch(actions.chart.updateUser(user, false));

  const handleDragEnd = (result: any, arrayHelpers: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    arrayHelpers.move(result.source.index, result.destination.index);
  };

  return (
    <Container>
      <FieldArray
        name="children"
        render={(arrayHelpers) => (
          <>
            <SectionTitle>
              <FormattedMessage id="form.user.teams" />
              {renderButtonSubForm(intl, arrayHelpers, FORM_TYPES.POSITION, 0)}
            </SectionTitle>
            {selectedUser.children && selectedUser.children.length > 0 && (
              <Row align="center">
                <LabelGroup items={2}>
                  <InputLabel margin="0 24px 0 0">
                    <FormattedMessage id="form.user.display.omr.team" />
                  </InputLabel>
                  <SwitchWithLabel
                    width="120px"
                    name="displayTeamOmrInfo"
                    values={[
                      { value: true, label: "form.user.yes" },
                      { value: false, label: "form.user.no" },
                    ]}
                    handleChange={(value: any) => {
                      selectedUser.children.forEach((u: any, i: any) => {
                        setFieldValue(`children.${i}.displayOmrInfo`, value);
                      });
                      updateUser({ ...selectedUser, children: selectedUser.children.map((c) => ({ ...c, displayOmrInfo: value })) });
                    }}
                  />
                </LabelGroup>
                <LabelGroup items={2}>
                  <InputLabel margin="0 24px 0 0">
                    <FormattedMessage id="form.user.hide.team.photo" />
                  </InputLabel>
                  <SwitchWithLabel
                    width="120px"
                    name="hideTeamPhoto"
                    values={[
                      { value: true, label: "form.user.yes" },
                      { value: false, label: "form.user.no" },
                    ]}
                    handleChange={(value: any) => {
                      selectedUser.children.forEach((u: any, i: any) => {
                        setFieldValue(`children.${i}.hidePhoto`, value);
                      });
                      updateUser({
                        ...selectedUser,
                        hideTeamPhoto: value,
                        children: selectedUser.children.map((c) => ({ ...c, hidePhoto: value })),
                      });
                    }}
                  />
                </LabelGroup>
              </Row>
            )}
            <DragDropContext onDragEnd={(result: any) => handleDragEnd(result, arrayHelpers)}>
              <Droppable droppableId="droppable">
                {(provided: any, snapshot: any) => (
                  <DragContainer {...provided.droppableProps} ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
                    {values.children &&
                      values.children.map((c: any, i: number) => (
                        <Draggable key={c.id} draggableId={c.id} index={i}>
                          {(p: any, s: any) => (
                            <Item ref={p.innerRef} {...p.draggableProps} {...p.dragHandleProps} isDragging={s.isDragging}>
                              <Badge>
                                <BadgeText>{i + 1}</BadgeText>
                              </Badge>
                              <Left>
                                <Row justify="space-between">
                                  <LabelGroup items={2}>
                                    <InputLabel>
                                      <FormattedMessage id="form.user.firstName" />
                                    </InputLabel>
                                    <Input
                                      type="text"
                                      id={`children.${i}.employees.0.firstName`}
                                      name={`children.${i}.employees.0.firstName`}
                                    />
                                  </LabelGroup>
                                  <LabelGroup items={2}>
                                    <InputLabel>
                                      <FormattedMessage id="form.user.lastName" />
                                    </InputLabel>
                                    <Input
                                      type="text"
                                      id={`children.${i}.employees.0.lastName`}
                                      name={`children.${i}.employees.0.lastName`}
                                    />
                                  </LabelGroup>
                                </Row>
                                {c.isNew && (
                                  <Row>
                                    <CompanyInput>
                                      <InputLabel>
                                        <FormattedMessage id="form.user.company" />
                                      </InputLabel>
                                      <Input
                                        type="text"
                                        id={`children.${i}.employees.0.company`}
                                        name={`children.${i}.employees.0.company`}
                                      />
                                    </CompanyInput>
                                  </Row>
                                )}
                                <Row justify="space-between">
                                  <LabelGroup items={2}>
                                    <InputLabel>
                                      <FormattedMessage id="form.user.title" />
                                    </InputLabel>
                                    <Input type="text" id={`children.${i}.title`} name={`children.${i}.title`} />
                                  </LabelGroup>
                                  <LabelGroup items={2} justify="flex-end">
                                    <Flex>
                                      <SwitchContainer>
                                        <SwitchWithLabel
                                          name={`children.${i}.isClosed`}
                                          values={[
                                            { value: false, label: "form.user.isClosed.display" },
                                            { value: true, label: "form.user.isClosed.hide" },
                                          ]}
                                        />
                                      </SwitchContainer>
                                      <TrashContainer onClick={() => arrayHelpers.remove(i)}>
                                        <Trash2 />
                                      </TrashContainer>
                                    </Flex>
                                  </LabelGroup>
                                </Row>
                              </Left>
                              <Right isDragging={s.isDragging}>
                                <Move size={30} />
                              </Right>
                            </Item>
                          )}
                        </Draggable>
                      ))}
                  </DragContainer>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      />
    </Container>
  );
};

export default TeamForm;
