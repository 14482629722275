export const ACTION_TYPES = {
  CHANGE_LOCALE: "CHANGE_LOCALE",
};

export interface I18nState {
  locale: string;
  messages: Record<string, unknown>;
}

export type I18nActionTypes = {
  type: typeof ACTION_TYPES.CHANGE_LOCALE;
  payload: string;
};
