import React from "react";
import { Upload } from "react-feather";

type Props = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FileInput: React.FC<Props> = ({ handleChange }) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <Upload color="black" size={24} onClick={handleClick} />
      <input type="file" ref={hiddenFileInput} onChange={(e) => handleChange(e)} style={{ display: "none" }} />
    </>
  );
};

export default FileInput;
