export const FORM_TYPES = {
  TEXT: "text",
  TOGGLE: "toggle",
  RADIO: "radio",
  EMPLOYEES: "employees",
  SUCCESSOR: "potentialSuccessor",
  NEXT_STEPS: "nextSteps",
  POSITION: "children",
};

export const TALENT_STATUS = {
  NONE: "None",
  COMPANY: "CKC",
  CORE: "Core Talent",
  GROUP: "GKC",
  LOW: "Low performer",
  PROMISING: "Promising Talent",
  TOO_NEW: "Too New to Rate",
};

export const KEY_POSITION = {
  GROUP: "GKP",
  COMPANY: "CKP",
  NONE: "None",
};

export const READINESS = {
  YEAR: "Within a year",
  MID: "Mid-term (1 -2 Y)",
  LONG: "Long-term (+ 2 Y)",
};

export const RETENTION_RISK = {
  NO: "N/A",
  LOW: "Low",
  MED: "Medium",
  HIGH: "High",
};

export const POTENTIAL = {
  NO: "N/A",
  LOW: "Low",
  MED: "Medium",
  HIGH: "High",
};

export const PERFORMANCE = {
  NO: "N/A",
  NOT_MEET: "Does not meet expectations",
  PARTIALLY: "Partially achieves",
  ACHIEVED: "Achieves objectives",
  EXCEEDED: "Exceeds",
  OUTSTANDING: "Outstanding",
};
