import common from "./common.i18n";
import form from "./form.i18n";
import home from "./home.i18n";

const en = {
  locale: "en",
  messages: {
    ...common,
    ...form,
    ...home,
  },
};

export default en;
