import styled from "styled-components";

import colors from "assets/style/colors";

const Divider = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: ${colors.borderGrey};
`;

export default Divider;
