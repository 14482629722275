import React from "react";
import styled from "styled-components";

import logo from "assets/images/logo_LVMH.svg";

const Container = styled.div`
  font-size: 6em;
  position: fixed;
  padding: 0;
  width: 1.8em;
  height: 1.8em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
  background-color: white;
  margin: 0 auto;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  &:before {
    z-index: 3;
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 1.8em;
    height: 1.8em;
    border-radius: 50%;
    border: 4px solid #f6f5f3;
    border-top: 4px solid #050c17;
    animation: spin 2s linear infinite;
  }
`;

const Logo = styled.div`
  overflow: hidden;
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  border-radius: 50%;
  background: url(${logo}) no-repeat 50%;
  background-size: 80%;
`;

interface OverlayProps {
  backgroundColor?: string;
}

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${(props: OverlayProps) => props.backgroundColor};
  z-index: 13;
`;

const Message = styled.div`
  margin-top: 0;
  text-align: center;
`;

interface Props {
  message?: string;
  backgroundColor?: string;
}

const Loader: React.FunctionComponent<Props> = ({ message, backgroundColor = "white" }) => {
  return (
    <Overlay backgroundColor={backgroundColor}>
      <Container>
        <Logo />
      </Container>
      <Message>{message}</Message>
    </Overlay>
  );
};

export default Loader;
