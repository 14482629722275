export const LOCAL_STORAGE = {
  PERMISSION_REFRESH: "refreshPermission",
  /* Used to add header on each api call */
  API_TOKEN: "Authorization",
  REDIRECT_URL: "redirectUrl",
  AUTH_TYPE: "auth_type",
};

export const isTokenValid = () => {
  try {
    const jwt = localStorage.getItem(LOCAL_STORAGE.API_TOKEN).replace("Bearer ", "");
    if (jwt) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};
