import type { Error } from "types/common.types";

type ActionTypes = {
  SHOW_ERROR: "SHOW_ERROR";
};

export const ACTION_TYPES: ActionTypes = {
  SHOW_ERROR: "SHOW_ERROR",
};

export interface ErrorState {
  error?: Error;
}

export type ErrorActionTypes = {
  type: typeof ACTION_TYPES.SHOW_ERROR;
  payload: Error;
};
