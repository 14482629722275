import React, { FunctionComponent } from "react";
import SnackbarProvider from "react-simple-snackbar";
import AppRouter from "routers/AppRouter";
import SnackbarWrapper from "components/snackbarWrapper";
import ReactGA from "react-ga4";
import * as ENV from "config/env";

const measurementId = ENV.GA_MEASUREMENT_ID;

ReactGA.initialize(measurementId);

const App: FunctionComponent = () => (
  <SnackbarProvider maxSnack={3}>
    <SnackbarWrapper />
    <AppRouter />
  </SnackbarProvider>
);

export default App;
