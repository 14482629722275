import { User } from "types/common.types";

export const updateAllChildren: any = (user: User, values: any) => {
  const { children = [], ...rest } = user;

  const updatedChildren = children.map((c: any) => ({ ...c, ...values }));

  return {
    ...rest,
    children: updatedChildren.map((c) => updateAllChildren(c, values)),
  };
};

export const haveAllChildrenProperty: any = (user: User, property: any, depth = 0) => {
  const { children = [] } = user;
  if (children.length === 0) {
    // @ts-ignore
    return !!user[property];
  }

  const isPresent = children
    // @ts-ignore
    .map((u) => haveAllChildrenProperty(u, property, depth + 1))
    .reduce(
      (accumulator, currentValue) => {
        if (accumulator === false) {
          return false;
        }
        if (currentValue === false) {
          accumulator = false;
        }
        return accumulator;
      },
      // @ts-ignore
      depth > 0 ? !!user[property] : true
    );

  return isPresent;
};

export const haveFirstsChildrenProperty: any = (user: User, property: any) => {
  const { children = [] } = user;
  if (children.length === 0) {
    // @ts-ignore
    return !!user[property];
  }

  const isPresent = children
    // @ts-ignore
    .map((u) => u[property])
    .reduce(
      (accumulator, currentValue) => {
        if (accumulator === false) {
          return false;
        }
        if (currentValue === false) {
          accumulator = false;
        }
        return accumulator;
      },
      // @ts-ignore
      true
    );

  return isPresent;
};
