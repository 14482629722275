import styled from "styled-components";

const SectionTitle = styled.span`
  font-family: LVBold;
  font-size: 16px;
  text-align: left;
  padding-bottom: 12px;
  margin-top: 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export default SectionTitle;
