import { I18nActionTypes, ACTION_TYPES } from "./i18n.types";

export const changeLocale = (locale: string): I18nActionTypes => {
  return {
    type: ACTION_TYPES.CHANGE_LOCALE,
    payload: locale,
  };
};

export default changeLocale;
