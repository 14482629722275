import React from "react";
import { PlusSquare } from "react-feather";
import styled from "styled-components";

import { haveFirstsChildrenProperty } from "utils/tree.utils";
import { FORM_TYPES, TALENT_STATUS, KEY_POSITION, READINESS } from "utils/form.utils";
import { User } from "types/common.types";
import defaultImage from "components/organigram/lib/images/no_image_user.jpg";
import { formatDate } from "../../utils/date.utils";

export type FormInput = {
  type?: string;
  name?: string;
  omr?: string;
  disabled?: boolean;
  key: string;
  values?: Array<string>;
  form?: (i: number) => Array<FormInput>;
};

export const TAB_KEYS = {
  POSITION: "position",
  EMPLOYEE: "employee",
  TEAM: "team",
};

export const inputs: Array<FormInput> = [
  {
    type: FORM_TYPES.TEXT,
    key: "id",
    disabled: true,
  },
  {
    type: FORM_TYPES.TEXT,
    key: "title",
  },
  {
    type: FORM_TYPES.RADIO,
    key: "keyPosition",
    values: [KEY_POSITION.NONE, KEY_POSITION.COMPANY, KEY_POSITION.GROUP],
  },
  {
    type: FORM_TYPES.EMPLOYEES,
    key: "employees",
  },
  {
    type: FORM_TYPES.SUCCESSOR,
    key: "potentialSuccessor",
  },
  {
    type: FORM_TYPES.POSITION,
    key: "children",
  },
];

export const nextStepsForm = (i: number, j: number) => [
  {
    type: FORM_TYPES.TEXT,
    key: "jobTitle",
    name: `employees.${i}.nextSteps.${j}.jobTitle`,
  },
  {
    type: FORM_TYPES.TEXT,
    key: "company",
    name: `employees.${i}.nextSteps.${j}.maison`,
  },
  {
    type: FORM_TYPES.RADIO,
    key: "readiness",
    name: `employees.${i}.nextSteps.${j}.readiness`,
    values: [READINESS.YEAR, READINESS.MID, READINESS.LONG],
  },
];

export const employeesForm = (i: number) => [
  {
    type: FORM_TYPES.TEXT,
    key: "firstName",
    name: `employees.${i}.firstName`,
  },
  {
    type: FORM_TYPES.TEXT,
    key: "lastName",
    name: `employees.${i}.lastName`,
  },
  {
    type: FORM_TYPES.TEXT,
    key: "jobTenure",
    name: `employees.${i}.jobTenure`,
  },
  {
    type: FORM_TYPES.TOGGLE,
    key: "futurA",
    name: `employees.${i}.futurA`,
    omr: true,
  },
  {
    type: FORM_TYPES.TOGGLE,
    key: "criticalExpert",
    name: `employees.${i}.criticalExpert`,
    omr: true,
  },
  {
    type: FORM_TYPES.RADIO,
    key: "talentStatus",
    name: `employees.${i}.talentStatus`,
    values: [
      TALENT_STATUS.NONE,
      TALENT_STATUS.COMPANY,
      TALENT_STATUS.CORE,
      TALENT_STATUS.GROUP,
      TALENT_STATUS.LOW,
      TALENT_STATUS.PROMISING,
      TALENT_STATUS.TOO_NEW,
    ],
  },
  {
    type: FORM_TYPES.NEXT_STEPS,
    key: `employees.${i}.nextSteps`,
    name: `employees.${i}.nextSteps`,
    form: (j: number) => nextStepsForm(i, j),
  },
];

export const potentialSuccessorForm = (i: number) => [
  {
    type: FORM_TYPES.TEXT,
    key: "firstName",
    name: `potentialSuccessor.${i}.employee.firstName`,
  },
  {
    type: FORM_TYPES.TEXT,
    key: "lastName",
    name: `potentialSuccessor.${i}.employee.lastName`,
  },
  {
    type: FORM_TYPES.TEXT,
    key: "maison",
    name: `potentialSuccessor.${i}.employee.maison`,
  },
  {
    type: FORM_TYPES.RADIO,
    key: "readiness",
    name: `potentialSuccessor.${i}.readiness`,
    values: ["None", READINESS.YEAR, READINESS.MID, READINESS.LONG],
  },
];

export const positionForm = (i: number) => [
  {
    type: FORM_TYPES.TEXT,
    key: "firstName",
    name: `children.${i}.employees.0.firstName`,
  },
  {
    type: FORM_TYPES.TEXT,
    key: "lastName",
    name: `children.${i}.employees.0.lastName`,
  },
  {
    type: FORM_TYPES.TEXT,
    key: "title",
    name: `children.${i}.title`,
  },
  {
    type: FORM_TYPES.TOGGLE,
    key: "isClosed",
    name: `children.${i}.isClosed`,
  },
];

export const subForms = {
  [FORM_TYPES.EMPLOYEES]: employeesForm,
  [FORM_TYPES.SUCCESSOR]: potentialSuccessorForm,
  [FORM_TYPES.POSITION]: positionForm,
};

const Container = styled.div`
  position: relative;
  cursor: pointer;
  margin-left: 18px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover > svg {
    fill: #fff;
  }
`;

export const renderButtonSubForm = (intl: any, arrayHelpers: any, type: string, index: number, onClick: any = undefined) => {
  /* const value = intl.formatMessage({
    id: `form.user.${type}.button`,
  }); */

  const defaultValue: any = {};

  if (type === FORM_TYPES.SUCCESSOR) {
    defaultValue.employee = {
      firstName: "",
      lastName: "",
      maison: "",
    };
    defaultValue.readiness = READINESS.YEAR;
  }

  if (type === FORM_TYPES.NEXT_STEPS) {
    defaultValue.company = "";
    defaultValue.readiness = READINESS.YEAR;
    defaultValue.jobTitle = "";
  }

  if (type === FORM_TYPES.EMPLOYEES) {
    defaultValue.lastName = "";
    defaultValue.firstName = "";
  }

  if (type === FORM_TYPES.POSITION) {
    defaultValue.id = `${Date.now()}`;
    defaultValue.isNew = true;
    defaultValue.isClosed = false;
    defaultValue.expanded = true;
    defaultValue.picture = defaultImage;
    defaultValue.nextSteps = [];
    defaultValue.potentialSuccessor = [];
    defaultValue.children = [];
    defaultValue.employees = [{ company: "" }];
  }

  return (
    <Container
      onClick={() => {
        arrayHelpers.insert(index, defaultValue);
        if (onClick) {
          onClick();
        }
      }}
    >
      <PlusSquare size={24} color="black" />
    </Container>
  );
};

const getDefaultValue = (type: string): any => {
  if (type === FORM_TYPES.RADIO) {
    return "none";
  }
  if (type === FORM_TYPES.TOGGLE) {
    return false;
  }
  if (type === FORM_TYPES.SUCCESSOR) {
    return [];
  }
  return "";
};

export const getInitialValues: any = (selectedUser: User) => {
  const initialValues: User = inputs.reduce(
    (accumulator: any, currentValue) => ({
      ...accumulator,
      [currentValue.key]:
        // @ts-ignore
        selectedUser && selectedUser[currentValue.key] ? selectedUser[currentValue.key] : getDefaultValue(currentValue.type),
    }),
    {}
  );

  initialValues.employees = initialValues.employees.map((e) => ({
    ...e,
    jobTenure: formatDate(e.jobTenure),
    groupStartDate: formatDate(e.groupStartDate),
  }));
  initialValues.displayTeamOmrInfo = haveFirstsChildrenProperty(selectedUser, "displayOmrInfo");
  initialValues.displaySuccessors = selectedUser.displaySuccessors;
  initialValues.hideTeamPhoto = selectedUser.hideTeamPhoto;
  return initialValues;
};
