import React from "react";
import styled from "styled-components";
import { Row as FlexRow } from "react-flexbox-grid";

type RowProps = {
  justify?: string;
  align?: string;
  padding?: string;
  margin?: string;
  fullWidth?: boolean;
  position?: string;
  direction?: string;
  gap?: string;
};

const Row = styled(({ fullWidth, align, padding, justify, ...props }) => <FlexRow {...props} />)`
  display: flex;
  flex-direction: row;
  ${(props: RowProps) => props.direction && `flex-direction: ${props.direction};`}
  ${(props: RowProps) => !props.direction && `flex-direction: row;`}
  ${(props: RowProps) => props.justify && `justify-content: ${props.justify};`}
  ${(props: RowProps) => props.fullWidth && "width: 100%;"}
  ${(props: RowProps) => props.align && `align-items: ${props.align};`}
  ${(props: RowProps) => props.padding && `padding: ${props.padding};`}
  ${(props: RowProps) => props.margin && `margin: ${props.margin};`}
  ${(props: RowProps) => props.position && `position: ${props.position};`}
  ${(props: RowProps) => props.gap && `gap: ${props.gap};`}
`;

export default Row;
