// Below are the functions that handle actual exporting:
// getSVGString ( svgNode ) and svgString2Image( svgString, width, height, format, callback )
import fontBase64 from "./organigram.font";

export function getSVGString(svgNode) {
  svgNode.setAttribute("xlink", "http://www.w3.org/1999/xlink");
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const cssStyleText = getCSSStyles(svgNode);
  // eslint-disable-next-line
  appendCSS(cssStyleText, svgNode);

  const serializer = new XMLSerializer();
  let svgString = serializer.serializeToString(svgNode);
  svgString = svgString.replace(/(\w+)?:?xlink=/g, "xmlns:xlink="); // Fix root xlink without namespace
  svgString = svgString.replace(/NS\d+:href/g, "xlink:href"); // Safari NS namespace fix

  return svgString;

  function getCSSStyles(parentElement) {
    const selectorTextArr = [];

    // Add Parent element Id and Classes to the list
    selectorTextArr.push(`#${parentElement.id}`);
    for (let c = 0; c < parentElement.classList.length; c += 1) {
      // eslint-disable-next-line
      if (!contains(`.${parentElement.classList[c]}`, selectorTextArr)) {
        selectorTextArr.push(`.${parentElement.classList[c]}`);
      }
    }

    // Add Children element Ids and Classes to the list
    const nodes = parentElement.getElementsByTagName("*");
    for (let i = 0; i < nodes.length; i += 1) {
      const { id } = nodes[i];
      // eslint-disable-next-line
      if (!contains(`#${id}`, selectorTextArr)) selectorTextArr.push(`#${id}`);

      const classes = nodes[i].classList;
      for (let c = 0; c < classes.length; c += 1) {
        // eslint-disable-next-line
        if (!contains(`.${classes[c]}`, selectorTextArr)) {
          selectorTextArr.push(`.${classes[c]}`);
        }
      }
    }

    // Extract CSS Rules
    let extractedCSSText = "";
    // eslint-disable-next-line
    for (let i = 0; i < document.styleSheets.length; i += 1) {
      // eslint-disable-next-line
      const s = document.styleSheets[i];

      try {
        if (!s.cssRules) continue;
      } catch (e) {
        if (e.name !== "SecurityError") throw e; // for Firefox
        continue;
      }

      const { cssRules } = s;
      for (let r = 0; r < cssRules.length; r += 1) {
        // eslint-disable-next-line
        if (contains(cssRules[r].selectorText, selectorTextArr)) extractedCSSText += cssRules[r].cssText;
      }
    }

    extractedCSSText += ` @font-face { font-family: 'LVRegular'; src: url(data:font/opentype;charset=utf-8;base64,${fontBase64});} * { font-family: 'LVRegular';} `;
    extractedCSSText += ` ul { padding: 0; margin: 0; } `;
    return extractedCSSText;

    function contains(str, arr) {
      return arr.indexOf(str) !== -1;
    }
  }

  function appendCSS(cssText, element) {
    const styleElement = document.createElement("style");
    styleElement.setAttribute("type", "text/css");
    styleElement.innerHTML = cssText;
    const refNode = element.hasChildNodes() ? element.children[0] : null;
    element.insertBefore(styleElement, refNode);
  }
}

export function svgString2Image(svgString, width, height, specificFormat, callback) {
  // const format = specificFormat || "png";

  const imgsrc = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`; // Convert SVG string to data URL

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.width = width;
  canvas.height = height;

  const image = new Image();
  image.onload = function load() {
    context.clearRect(0, 0, width, height);
    context.drawImage(image, 0, 0, width, height);

    canvas.toBlob(function toBlob(blob) {
      const filesize = `${Math.round(blob.length / 1024)} KB`;
      if (callback) callback(blob, filesize);
    });
  };
  image.src = imgsrc;
}
