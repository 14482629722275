import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Minus, Plus, Minimize, Save, RefreshCw, Camera } from "react-feather";
import { Range, Direction, getTrackBackground } from "react-range";
import api from "api";
import { ERROR_TYPES, extractApiError, MESSAGE } from "utils/error.utils";
import Loader from "components/loader";
import { User } from "types/common.types";
import "./organigram.css";
import colors from "assets/style/colors";
import "./lib/css/organigram.css";
import "./lib/css/organigram-bck.css";
import { RootState } from "store/types";
import actions from "store/actions";
import { updateAllChildren } from "utils/tree.utils";
import { ChartType, triggerChartConsultation, triggerEvent } from "gtm/gtmKeys";
import TreeChart from "./lib/js/organigram";
import { MAX_CHILDREN } from "./lib/js/organigram.constants";
import FileInput from "../input/FileInput";
import { formatName } from "./lib/js/organigram.render";
import { LOCAL_STORAGE } from "../../utils/auth.utils";

const Row = styled.div`
  display: flex;
`;

const ActionContainer = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
  display: flex;
  z-index: 2;
  flex-direction: column;
`;

const ActionGroup = styled.div`
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background: white;
  margin: 6px;
  padding: 3px;
`;

const ActionGroupButton = styled(ActionGroup)`
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
`;

const actionsColor = "#888";

const Action = styled.div`
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  & svg {
    stroke: ${actionsColor};
  }
  &:hover svg {
    stroke: black;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 4px;
  left: 0;
  display: flex;
  flex-direction: row;
  display: -webkit-box;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
`;

type ButtonProps = {
  active?: boolean;
};

const StyledButton = styled.button`
  background-color: ${(props: ButtonProps) => (props.active ? colors.lightGrey : "white")};
  font-size: 16px;
  padding: 6px 18px;
  cursor: pointer;
  border: none;
  transition: all 0.2s;
  &:hover {
    background-color: ${colors.lightGrey};
  }
`;

const Thumb = styled.div`
  height: 8px;
  border-radius: 8px;
  width: 16px;
  background-color: ${actionsColor};
  &:hover {
    background-color: black;
  }
`;

interface TrackProps {
  background: string;
}

const Track = styled.div`
  height: 80px;
  width: 4px;
  border-radius: 4px;
  background: ${(props: TrackProps) => props.background};
`;

let transform: any = null;
const orgChartTree: any = new TreeChart();

const Organigram: React.FunctionComponent = () => {
  const isRefreshButton = localStorage.getItem(LOCAL_STORAGE.PERMISSION_REFRESH) === "true";
  const [chartType, setChartType] = useState<ChartType>("simple");
  const [separator, setSeparator] = useState("1");
  const [range, setRange] = useState([1]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();

  const selectUserToEdit = (user: User) => dispatch(actions.chart.selectUserToEdit(user));
  const updateUser = (user: any) => dispatch(actions.chart.updateUser(user));
  const setChart = (chart: User) => dispatch(actions.chart.updateChart(chart));
  const expandManager = (user: any) => dispatch(actions.chart.expandManager(user));
  const shrinkManager = (user: any) => dispatch(actions.chart.shrinkManager(user));
  const showError = (e: any) => dispatch(actions.error.showError(e));
  const setSearchBar = (searchBar: string) => dispatch(actions.chart.setSearchBar(searchBar));

  const orgChart = useSelector((state: RootState) => state.chart.orgChart);
  const rootUser = useSelector((state: RootState) => state.chart.rootUser);
  const loadingChart = useSelector((state: RootState) => state.chart.loadingChart);

  const loadManager = async (parentId: any, refresh?: boolean) => {
    setLoading(true);

    try {
      const { data } = await api.chart.getChart(parentId);

      if (refresh) {
        setChart(data);
      } else {
        expandManager(data);
      }
    } catch (err: any) {
      if (extractApiError(err)) {
        showError({
          type: ERROR_TYPES.API,
          details: extractApiError(err),
          msg: MESSAGE.DEFAULT,
        });
      } else {
        showError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const generateOrga = (type: ChartType = chartType, s = "1"): void => {
    setChartType(type);
    setSeparator(s);

    orgChartTree
      .container(".chart-container")
      .separatorRatio(parseFloat(s) || 1)
      // function used to make spaces between nodes
      .separation(function separation(a: any, b: any) {
        // don't show node space if node > max children per line
        if (a.parent === b.parent && a.parent.children.length > MAX_CHILDREN) {
          const index = a.parent.children.findIndex((element: any) => element.id === a.id);
          if (index + 1 > MAX_CHILDREN) {
            return 0;
          }
        }
        // eslint-disable-next-line radix
        return parseFloat(s) || 1;
      })
      .template(type)
      .svgWidth(window.innerWidth)
      .svgHeight(window.innerHeight)
      .initialZoom(0.6)
      .backgroundColor("#ffffff")
      .defaultTextFill("#000000")
      .nodeTextFill("#000000")
      .rootNode(rootUser)
      .onNodeClick((nodeId: any, data: any) => {
        const user = data.rawData;
        triggerEvent("parameterOpened");
        selectUserToEdit(user);
      })
      .onExpandManager(async (node: any) => {
        const { rawData } = node.data;
        const { parentId } = rawData;
        loadManager(parentId);
      })
      .onShrinkManager((node: any) => {
        const { rawData } = node.data;
        shrinkManager(rawData);
      })
      .onExpandClick((node: any) => {
        const children = node.data.rawData.children.map((c: any) => ({ ...c, id: c.id, expanded: true }));

        updateUser({ id: node.data.rawData.id, children });
        // updateUser
      })
      .onShrinkClick((node: any) => {
        updateUser({ ...updateAllChildren(node.data.rawData, { expanded: false }) });
        // updateUser
      })
      .onRemoveNode((node: any) => {
        updateUser({ ...updateAllChildren(node.data.rawData, { expanded: false }) });
        updateUser({ ...node.data.rawData, id: node.data.rawData.id, isClosed: true });
      })
      .onNodeUpdate((data: User) => {
        selectUserToEdit(data);
      })
      .onChartMove((t: any) => {
        transform = t;
      })
      .rawData(orgChart)
      .flattenData()
      .render();
    orgChartTree.getChartState().chart.attr("transform", transform);
  };

  useEffect(() => {
    if (orgChart !== undefined) {
      generateOrga(chartType, separator);
    }
    // eslint-disable-next-line
  }, [orgChart]);

  useEffect(() => {
    triggerChartConsultation(chartType);
  }, [chartType]);

  useEffect(() => {
    transform = null;
    setRange([1]);
    setSeparator("1");
    // orgChartTree = new TreeChart();
    // eslint-disable-next-line
  }, [rootUser]);

  const changeChartType = async (type: ChartType) => {
    triggerChartConsultation(type);
    switch (type) {
      case "omr":
      case "simple":
      case "next":
      case "full":
        generateOrga(type, separator);
        orgChartTree.getChartState().chart.attr("transform", transform);
        break;
      default:
        break;
    }
  };

  const refreshOrgChart = async () => {
    try {
      setLoading(true);
      const ids: string[] = [];
      triggerEvent("chartLoaded");
      triggerChartConsultation(chartType);
      orgChart.employees.map((e) => ids.push(e.employeeId));
      orgChart.children.map((c) => c.employees.map((e) => ids.push(e.employeeId)));
      orgChart.children.map((c2) => c2.children.map((c3) => c3.employees.map((e) => ids.push(e.employeeId))));
      await api.chart.refresh(ids.join(",")).then(() => {
        loadManager(rootUser.positionCode, true).then(() => setLoading(false));
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const exportImageOrganigramTree = () => {
    const names = rootUser.employees
      .map((e) => {
        if (e.lastName || e.firstName) {
          return `${e.firstName} ${e.lastName}`;
        }
        return "";
      })
      .join(", ");
    orgChartTree.exportImage(`Org Charts ${names} - ${chartType}`);
    triggerEvent("screenshot");
    generateOrga();
  };

  const exportJsonOrganigramTree = () => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([JSON.stringify(orgChart)], { type: "application/json" });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement("a");
    const names = rootUser.employees
      .map((e) => {
        if (e.lastName || e.firstName) {
          return `${e.firstName} ${e.lastName}`;
        }
        return "";
      })
      .join(", ");
    a.download = `Org Charts ${names} - ${chartType}`;
    a.href = window.URL.createObjectURL(blob);
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    triggerEvent("orgChartExported");
    a.dispatchEvent(clickEvent);
    a.remove();
  };

  const importJsonOrganigramTree = (e: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const str = String(fileReader.result);
      const org = JSON.parse(str);
      setChart(org);
      setSearchBar(
        `${formatName(
          org.employees && org.employees[0] ? org.employees[0].firstName : "",
          org.employees && org.employees[0] ? org.employees[0].lastName : ""
        )} - ${org.title} ${org.subsidiary ? `- ${org.subsidiary}` : ""}`.trim()
      );
    };
    fileReader.readAsText(e.target.files[0]);
    triggerEvent("orgChartImported");
  };

  if (loadingChart) {
    return <Loader message={intl.formatMessage({ id: "common.loading.chart" })} />;
  }

  if (!orgChart) {
    return <div />;
  }

  // @ts-ignore
  return (
    <>
      {loading && <Loader backgroundColor="rgba(0, 0, 0, 0.6)" />}
      <Row>
        <div className="chart-container">
          <ButtonContainer>
            <ActionGroupButton>
              {(["simple", "omr", "next", "full"] as ChartType[]).map((key) => (
                <StyledButton key={key} active={chartType === key} type="button" onClick={() => changeChartType(key)}>
                  <FormattedMessage id={`common.organizationalChart.button.${key}`} />
                </StyledButton>
              ))}
            </ActionGroupButton>
          </ButtonContainer>
          <ActionContainer>
            {isRefreshButton && (
              <ActionGroup>
                <Action onClick={() => refreshOrgChart()}>
                  <RefreshCw color="black" size={20} />
                </Action>
              </ActionGroup>
            )}
            <ActionGroup>
              <Action onClick={() => exportJsonOrganigramTree()}>
                <Save color="black" size={24} />
              </Action>
              <Action>
                <FileInput handleChange={(e) => importJsonOrganigramTree(e)} />
              </Action>
            </ActionGroup>
            <ActionGroup>
              <Action onClick={() => exportImageOrganigramTree()}>
                <Camera color="black" size={24} />
              </Action>
            </ActionGroup>
            <ActionGroup>
              <Action onClick={() => orgChartTree.center()}>
                <Minimize color="black" size={24} />
              </Action>
            </ActionGroup>
            <ActionGroup>
              <Action onClick={() => orgChartTree.zoomIn()}>
                <Plus color="black" size={24} />
              </Action>
              <Action onClick={() => orgChartTree.zoomOut()}>
                <Minus color="black" size={24} />
              </Action>
            </ActionGroup>
            <ActionGroup>
              <RangeContainer>
                <Range
                  step={0.05}
                  min={1}
                  max={2}
                  values={range}
                  direction={Direction.Down}
                  onChange={(values) => {
                    setRange(values);
                    generateOrga(chartType, values.length ? values[0].toString() : "1");
                  }}
                  renderTrack={({ props, children }) => (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <Track
                      background={getTrackBackground({
                        values: range,
                        direction: Direction.Down,
                        colors: [actionsColor, colors.grey],
                        min: 1,
                        max: 2,
                      })}
                      ref={props.ref}
                    >
                      {children}
                    </Track>
                  )}
                  renderThumb={({ props }) => <Thumb {...props} />}
                />
              </RangeContainer>
            </ActionGroup>
          </ActionContainer>
        </div>
      </Row>
    </>
  );
};

export default Organigram;
