import * as d3 from "d3";
import { getSVGString, svgString2Image } from "./organigram.image";
import { renderLegend, computeHeight, renderNode } from "./organigram.render";
import { setDropShadowId, isEdge, rgbaObjToColor, initializeEnterExitUpdatePattern, diagonal, parseTransform } from "./organigram.utils";
import { MAX_CHILDREN, BOX_Y_SPACING, NODE_WIDTH, BOX_X_SPACING, defaultImage, COLORS } from "./organigram.constants";
import { READINESS } from "../../../../utils/form.utils";
import { triggerEvent } from "../../../../gtm/gtmKeys";

const titleHeight = 75;

const omrLegendWidth = 1642;
const legendHeight = 185;
const legendTransformY = 100;
const nextLegendWidth = 500;

class TreeChart {
  absoluteNodeTreelineDistance = 73;

  absoluteImageY = -105;

  constructor() {
    // Exposed variables
    const attrs = {
      id: `ID${Math.floor(Math.random() * 1000000)}`, // Id for event handlings
      template: "none",
      svgWidth: 800,
      svgHeight: 600,
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 0,
      container: "body",
      defaultTextFill: "#2C3E50",
      nodeTextFill: "white",
      defaultFont: "Helvetica",
      backgroundColor: "#fafafa",
      rawData: null,
      data: null,
      // first Node = position searched
      rootNode: null,
      depth: 180,
      duration: 600,
      strokeWidth: 3,
      dropShadowId: null,
      behaviors: null,
      separatorRatio: 1,
      initialZoom: 1,
      imagePath: ".",
      // onNodeClick: (nodeId, node, chart) => {},
      onNodeClick: () => {},
      // onExpandManager: (node) => {},
      onExpandManager: () => {},
      // onShrinkManager: (node) => {},
      onShrinkManager: () => {},
      // onRemoveNode: (nodeId, node, chart) => {},
      onRemoveNode: () => {},
      // onExpandClick: (node) => {},
      onExpandClick: () => {},
      // onShrinkClick: (node) => {},
      onShrinkClick: () => {},
      // onNodeUpdate: (data, nodeId, chart) => {},
      onNodeUpdate: () => {},
      // onChartMove: (transform) => {},
      onChartMove: () => {},
      // separation: (a, b) => {},
      separation: () => {},
    };

    this.getChartState = () => attrs;

    // Dynamically set getter and setter functions for Chart class
    Object.keys(attrs).forEach((key) => {
      // @ts-ignore
      this[key] = function (_) {
        const string = `attrs['${key}'] = _`;
        if (!arguments.length) {
          // eslint-disable-next-line no-eval
          return eval(`attrs['${key}'];`);
        }
        // eslint-disable-next-line no-eval
        eval(string);
        return this;
      };
    });

    initializeEnterExitUpdatePattern();
  }

  // This method retrieves passed node's children ID's (including node)
  getNodeChildrenIds({ data, children, _children }, nodeIdsStore) {
    // Store current node ID
    nodeIdsStore.push(data.nodeId);

    // Loop over children and recursively store descendants id (expanded nodes)
    if (children) {
      children.forEach((d) => {
        this.getNodeChildrenIds(d, nodeIdsStore);
      });
    }

    // Loop over _children and recursively store descendants id (collapsed nodes)
    if (_children) {
      _children.forEach((d) => {
        this.getNodeChildrenIds(d, nodeIdsStore);
      });
    }

    // Return result
    return nodeIdsStore;
  }

  // This method can be invoked via chart.setZoomFactor API, it zooms to particulat scale
  setZoomFactor(zoomLevel) {
    const attrs = this.getChartState();
    const { calc } = attrs;

    // Store passed zoom level
    attrs.initialZoom = zoomLevel;

    // Rescale container element accordingly
    attrs.centerG.attr("transform", ` translate(${calc.centerX}, ${calc.nodeMaxHeight / 2}) scale(${attrs.initialZoom})`);
  }

  center() {
    const attrs = this.getChartState();
    const { behaviors, svg, initialZoom } = attrs;
    const { zoom } = behaviors;

    const box = svg.node().getBBox();
    const parent = svg.node().parentElement;
    const fullWidth = parent.clientWidth;
    const fullHeight = parent.clientHeight - legendTransformY;

    triggerEvent("refocusTriggered");
    let t = svg
      .patternify({
        tag: "g",
        selector: "chart",
      })
      .attr("transform");
    let parsed = {};
    if (t) {
      parsed = parseTransform(t);
    }
    // get current scale from svg or 1
    const currentScale = t ? parsed.scale[0] : 1;
    const scale = Math.min(((fullWidth - 40) * currentScale) / box.width, (fullHeight * currentScale) / box.height);
    let minWidth = 0;
    let maxWidth = 0;

    // Iterate trough all nodes to get min and max X to center the graph
    const treeData = attrs.layouts.treemap(attrs.root);
    treeData.descendants().forEach((d) => {
      const { translateX } = d;
      if (translateX < minWidth) {
        minWidth = translateX;
      }
      if (translateX > maxWidth) {
        maxWidth = translateX;
      }
    });

    const midX = -(minWidth * initialZoom + maxWidth * initialZoom) / 2;

    svg.call(zoom.transform, d3.zoomIdentity.translate(midX, 0).scale(1)).call(zoom.scaleBy, scale);

    t = svg
      .patternify({
        tag: "g",
        selector: "chart",
      })
      .attr("transform");
    const parsedAfter = parseTransform(t);
    const currentScaleAfter = parsedAfter.scale[0];
    svg.call(zoom.transform, d3.zoomIdentity.translate(parsedAfter.translate[0], 0).scale(currentScaleAfter));
  }

  zoomIn() {
    const attrs = this.getChartState();
    const { behaviors } = attrs;
    const { zoom } = behaviors;

    attrs.svg.transition().duration(600).call(zoom.scaleBy, 1.4);
  }

  zoomOut() {
    const attrs = this.getChartState();
    const { behaviors } = attrs;
    const { zoom } = behaviors;

    attrs.svg.transition().duration(600).call(zoom.scaleBy, 0.6);
  }

  render() {
    // InnerFunctions which will update visuals

    const attrs = this.getChartState();

    // Drawing containers
    const container = d3.select(attrs.container);
    const containerRect = container.node().getBoundingClientRect();
    if (containerRect.width > 0) attrs.svgWidth = containerRect.width;
    if (containerRect.height > 0) attrs.svgHeight = containerRect.height;

    // Attach drop shadow id to attrs object
    setDropShadowId(attrs);

    // Calculated properties
    const calc = {
      id: null,
      chartTopMargin: null,
      chartLeftMargin: null,
      chartWidth: null,
      chartHeight: null,
    };
    calc.id = `ID${Math.floor(Math.random() * 1000000)}`; // id for event handlings
    calc.chartLeftMargin = attrs.marginLeft;
    calc.chartTopMargin = attrs.marginTop;
    calc.chartWidth = attrs.svgWidth - attrs.marginRight - calc.chartLeftMargin;
    calc.chartHeight = attrs.svgHeight - attrs.marginBottom - calc.chartTopMargin;
    attrs.calc = calc;

    // Get maximum node width and height
    calc.nodeMaxWidth = d3.max(attrs.data, ({ width }) => width);
    calc.nodeMaxHeight = d3.max(attrs.data, ({ height }) => height);

    // Calculate max node depth (it's needed for layout heights calculation)
    attrs.depth = calc.nodeMaxHeight + 100;
    calc.centerX = calc.chartWidth / 2;

    //* *******************  LAYOUTS  ***********************
    const layouts = {
      treemap: null,
    };
    attrs.layouts = layouts;

    // Generate tree layout function
    layouts.treemap = d3
      .tree()
      .size([calc.chartWidth, calc.chartHeight])
      .nodeSize([calc.nodeMaxWidth + 100, calc.nodeMaxHeight])
      .separation(attrs.separation);

    // ******************* BEHAVIORS . **********************
    const behaviors = {
      zoom: null,
    };

    // Get zooming function
    behaviors.zoom = d3.zoom().on("zoom", (d) => this.zoomed(d));
    attrs.behaviors = behaviors;

    //* ***************** ROOT node work ************************

    // Convert flat data to hierarchical
    attrs.root = d3
      .stratify()
      .id(({ nodeId }) => nodeId)
      .parentId(({ parentNodeId }) => parentNodeId)(attrs.data);

    // Set child nodes enter appearance positions
    attrs.root.x0 = 0;
    attrs.root.y0 = 0;

    /** Get all nodes as array (with extended parent & children properties set)
     This way we can access any node's parent directly using node.parent - pretty cool, huh?
     */

    attrs.allNodes = attrs.layouts.treemap(attrs.root).descendants();
    attrs.allNodes.forEach((node) => {
      if (node.data.rawData.isClosed) {
        this.removeNode(node.data.nodeId);
      }
    });
    // Assign direct children and total subordinate children's cound
    attrs.allNodes.forEach((d) => {
      Object.assign(d.data, {
        directSubordinates: d.children ? d.children.length : 0,
        totalSubordinates: d.descendants().length - 1,
      });
    });

    this.collapse(attrs.root);
    this.expandSomeNodes(attrs.root);
    attrs.root.data.expanded = true;

    // Collapse all children at first
    /* if (attrs.root.children) {
      attrs.root.children.forEach((d) => this.collapse(d));

      // Then expand some nodes, which have `expanded` property set
      attrs.root.children.forEach((d) => this.expandSomeNodes(d));

      attrs.root.data.expanded = true;
    } */

    // *************************  DRAWING **************************
    // Add svg
    const svg = container
      .patternify({
        tag: "svg",
        selector: "svg-chart-container",
      })
      .attr("width", attrs.svgWidth)
      .attr("height", attrs.svgHeight)
      .attr("font-family", attrs.defaultFont)
      .call(behaviors.zoom)
      // trigger click to hide SearchBar
      .on("click", () =>
        document.dispatchEvent(
          new MouseEvent("mousedown", {
            view: window,
          })
        )
      )
      .attr("cursor", "move")
      .style("background-color", attrs.backgroundColor);
    attrs.svg = svg;

    // Add container g element
    const chart = svg
      .patternify({
        tag: "g",
        selector: "chart",
      })
      .attr("transform", `translate(0,0) scale(${attrs.initialZoom})`);

    // Add one more container g element, for better positioning controls
    attrs.centerG = chart
      .patternify({
        tag: "g",
        selector: "center-group",
      })
      .attr("transform", `translate(${calc.centerX},90) scale(${attrs.initialZoom})`);

    attrs.chart = chart;

    // ************************** ROUNDED AND SHADOW IMAGE  WORK USING SVG FILTERS **********************

    // Adding defs element for rounded image
    attrs.defs = svg.patternify({
      tag: "defs",
      selector: "image-defs",
    });

    // Adding defs element for image's shadow
    const filterDefs = svg.patternify({
      tag: "defs",
      selector: "filter-defs",
    });

    // Adding shadow element - (play with svg filter here - https://bit.ly/2HwnfyL)
    const filter = filterDefs
      .patternify({
        tag: "filter",
        selector: "shadow-filter-element",
      })
      .attr("id", attrs.dropShadowId)
      .attr("y", `${-50}%`)
      .attr("x", `${-50}%`)
      .attr("height", `${200}%`)
      .attr("width", `${200}%`);

    // Add gaussian blur element for shadows - we can control shadow length with this
    filter
      .patternify({
        tag: "feGaussianBlur",
        selector: "feGaussianBlur-element",
      })
      .attr("in", "SourceAlpha")
      .attr("stdDeviation", 3.1)
      .attr("result", "blur");

    // Add fe-offset element for shadows -  we can control shadow positions with it
    filter
      .patternify({
        tag: "feOffset",
        selector: "feOffset-element",
      })
      .attr("in", "blur")
      .attr("result", "offsetBlur")
      .attr("dx", 4.28)
      .attr("dy", 4.48)
      .attr("x", 8)
      .attr("y", 8);

    // Add fe-flood element for shadows - we can control shadow color and opacity with this element
    filter
      .patternify({
        tag: "feFlood",
        selector: "feFlood-element",
      })
      .attr("in", "offsetBlur")
      .attr("flood-color", "black")
      .attr("flood-opacity", 0.3)
      .attr("result", "offsetColor");

    // Add feComposite element for shadows
    filter
      .patternify({
        tag: "feComposite",
        selector: "feComposite-element",
      })
      .attr("in", "offsetColor")
      .attr("in2", "offsetBlur")
      .attr("operator", "in")
      .attr("result", "offsetBlur");

    // Add feMerge element for shadows
    const feMerge = filter.patternify({
      tag: "feMerge",
      selector: "feMerge-element",
    });

    // Add feMergeNode element for shadows
    feMerge
      .patternify({
        tag: "feMergeNode",
        selector: "feMergeNode-blur",
      })
      .attr("in", "offsetBlur");

    // Add another feMergeNode element for shadows
    feMerge
      .patternify({
        tag: "feMergeNode",
        selector: "feMergeNode-graphic",
      })
      .attr("in", "SourceGraphic");

    // Display tree contenrs
    this.update(attrs.root);

    // #########################################  UTIL FUNCS ##################################
    // This function restyles foreign object elements ()
    /* d3.select(window).on(`resize.${attrs.id}`, () => {
      const containerRect = container.node().getBoundingClientRect();
      //  if (containerRect.width > 0) attrs.svgWidth = containerRect.width;
      //	main();
    }); */

    return this;
  }

  // This function can be invoked via chart.addNode API, and it adds node in tree at runtime
  addNode(obj) {
    const attrs = this.getChartState();
    attrs.data.push(obj);

    // Update state of nodes and redraw graph
    this.updateNodesState();
    return this;
  }

  updateNodeData(rawData) {
    const attrs = this.getChartState();
    switch (rawData.nodeId) {
      // Update Original Node
      case "node-0":
        attrs.rawData = rawData;
        break;
      default: {
        const oldRawData = attrs.rawData;
        // If updated user is N-1
        if (rawData.parentId === oldRawData.id) {
          const index = oldRawData.children.findIndex((child) => child.id === rawData.id);
          if (index !== -1) {
            oldRawData.children[index] = rawData;
          }
          // Updated user is N-2
        } else {
          const childIndex = oldRawData.children.findIndex((child) => child.id === rawData.parentId);
          if (childIndex !== -1) {
            const objectIndex = oldRawData.children[childIndex].children.findIndex((user) => user.id === rawData.id);
            if (objectIndex !== -1) {
              oldRawData.children[childIndex].children[objectIndex] = rawData;
            }
          }
        }
        attrs.rawData = oldRawData;
        break;
      }
    }
    this.flattenData();
    this.render();

    return this;
  }

  // This function can be invoked via chart.removeNode API, and it removes node from tree at runtime
  removeNode(nodeId) {
    const attrs = this.getChartState();
    const node = attrs.allNodes.filter(({ data }) => data.nodeId === nodeId)[0];

    // Remove all node childs
    if (node) {
      // Retrieve all children nodes ids (including current node itself)
      const nodeChildrenIds = this.getNodeChildrenIds(node, []);
      const childIndex = attrs.rawData.children.findIndex((child) => child.nodeId === nodeId);
      if (childIndex !== -1) {
        attrs.rawData.children[childIndex].isClosed = true;
      } else {
        attrs.rawData.children.forEach((child) => {
          const index = child.children.findIndex((secndChild) => secndChild.nodeId === nodeId);
          if (index !== -1) {
            // eslint-disable-next-line no-param-reassign
            child.children[index].isClosed = true;
          }
        });
      }
      // Filter out retrieved nodes and reassign data
      attrs.data = attrs.data.filter((d) => !nodeChildrenIds.includes(d.nodeId));

      const updateNodesState = this.updateNodesState.bind(this);
      // Update state of nodes and redraw graph
      updateNodesState();
    }
  }

  // This function basically redraws visible graph, based on nodes state
  update({ x0, y0, x, y }) {
    const attrs = this.getChartState();
    attrs.layouts.treemap.separation(attrs.separation);
    // const { calc } = attrs;

    // eslint-disable-next-line no-param-reassign
    y = titleHeight;
    // eslint-disable-next-line no-param-reassign
    y0 = titleHeight;
    attrs.root.y = titleHeight;
    attrs.root.y0 = titleHeight;

    //  Assigns the x and y position for the nodes
    const treeData = attrs.layouts.treemap(attrs.root);

    // get max node height by depth to force same height on same line
    const maxHeightByDepths = {};
    treeData.descendants().forEach((d) => {
      const { depth, data } = d;
      const { height } = data;
      if (height > maxHeightByDepths[depth] || !maxHeightByDepths[depth]) {
        maxHeightByDepths[depth] = height;
      }
    });

    // Get tree nodes and links and attach some properties
    const nodes = treeData.descendants().map((d) => {
      // If at least one property is already set, then we don't want to reset other properties
      if (d.width) return d;

      // Declare properties with default values
      let imageWidth = 100;
      let imageHeight = 100;
      let imageBorderColor = "steelblue";
      let imageBorderWidth = 0;
      let imageRx = 0;
      let imageCenterTopDistance = 0;
      let imageCenterLeftDistance = 0;
      let borderColor = "steelblue";
      let backgroundColor = "steelblue";
      const { width } = d.data;
      let dropShadowId = `none`;

      // Override default values based on data
      if (d.data.nodeImage && d.data.nodeImage.shadow) {
        dropShadowId = `url(#${attrs.dropShadowId})`;
      }
      if (d.data.nodeImage && d.data.nodeImage.width) {
        imageWidth = d.data.nodeImage.width;
      }
      if (d.data.nodeImage && d.data.nodeImage.height) {
        imageHeight = d.data.nodeImage.height;
      }
      if (d.data.nodeImage && d.data.nodeImage.borderColor) {
        imageBorderColor = rgbaObjToColor(d.data.nodeImage.borderColor);
      }
      if (d.data.nodeImage && d.data.nodeImage.borderWidth) {
        imageBorderWidth = d.data.nodeImage.borderWidth;
      }
      if (d.data.nodeImage && d.data.nodeImage.centerTopDistance) {
        imageCenterTopDistance = d.data.nodeImage.centerTopDistance;
      }
      if (d.data.nodeImage && d.data.nodeImage.centerLeftDistance) {
        imageCenterLeftDistance = d.data.nodeImage.centerLeftDistance;
      }
      if (d.data.borderColor) {
        borderColor = rgbaObjToColor(d.data.borderColor);
      }
      if (d.data.backgroundColor) {
        backgroundColor = rgbaObjToColor(d.data.backgroundColor);
      }
      if (d.data.nodeImage && d.data.nodeImage.cornerShape.toLowerCase() === "circle") {
        imageRx = Math.max(imageWidth, imageHeight);
      }
      if (d.data.nodeImage && d.data.nodeImage.cornerShape.toLowerCase() === "rounded") {
        imageRx = Math.min(imageWidth, imageHeight) / 6;
      }
      d.data.height = maxHeightByDepths[d.depth];
      // Extend node object with calculated properties
      return Object.assign(d, {
        imageWidth,
        imageHeight,
        imageBorderColor,
        imageBorderWidth,
        borderColor,
        backgroundColor,
        imageRx,
        width,
        height: maxHeightByDepths[d.depth],
        imageCenterTopDistance,
        imageCenterLeftDistance,
        dropShadowId,
      });
    });

    // Get all links
    const links = treeData.descendants().slice(1);

    // Set constant depth for each nodes
    // eslint-disable-next-line
    nodes.forEach((d) => (d.y = d.depth * attrs.depth + titleHeight));

    // ------------------- FILTERS ---------------------
    // Add patterns for each node (it's needed for rounded image implementation)
    /* const patternsSelection = attrs.defs.selectAll(".pattern").data(nodes, ({ id }) => id);

    // Define patterns enter selection
    const patternEnterSelection = patternsSelection.enter().append("pattern");

    // Patters update selection
    const patterns = patternEnterSelection
      .merge(patternsSelection)
      .attr("class", "pattern")
      .attr("height", 1)
      .attr("width", 1)
      .attr("id", ({ id }) => id);

    // Add images to patterns
    patterns
      .patternify({
        tag: "image",
        selector: "pattern-image",
        data: (d) => [d],
      })
      .attr("x", 0)
      .attr("y", 0)
      .attr("height", ({ imageWidth }) => imageWidth)
      .attr("width", ({ imageHeight }) => imageHeight)
      .attr("xlink:href", ({ data }) => `data:image/jpeg;charset=utf-8;base64,${data.nodeImage.url}`)
      .attr("viewbox", ({ imageWidth, imageHeight }) => `0 0 ${imageWidth * 2} ${imageHeight}`)
      .attr("preserveAspectRatio", "xMidYMin slice");

    // Remove patterns exit selection after animation
    patternsSelection.exit().transition().duration(attrs.duration).remove(); */

    // --------------------------  NODES ----------------------
    // Get nodes selection

    const currentTemplate = attrs.template;
    let numberOfChildren = attrs.rawData.children.length || 0;

    const nodesSelection = attrs.centerG.selectAll("g.node").data(nodes, ({ id }) => id);

    // Enter any new nodes at the parent's previous position.
    const nodeEnter = nodesSelection
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("cursor", "pointer")
      .on("click", ({ data }) => {
        const classes = [...d3.event.srcElement.classList];

        if (
          classes.includes("node-button-circle") ||
          classes.includes("node-button-close-circle") ||
          classes.includes("node-button-circle-manager")
        ) {
          return;
        }
        attrs.onNodeClick(data.nodeId, data, this);
      });

    // Add background rectangle for the nodes
    nodeEnter.patternify({
      tag: "rect",
      selector: "node-rect",
      data: (d) => [d],
    });
    // Node update styles
    const nodeUpdate = nodeEnter.merge(nodesSelection).style("font", "12px sans-serif");

    /*
     * Start : node content with template
     */
    // Add foreignObject element inside rectangle
    const fo = nodeUpdate.patternify({
      tag: "foreignObject",
      selector: "node-foreign-object",
      data: (d) => [d],
    });

    // Add foreign object
    fo.patternify({
      tag: "xhtml:div",
      selector: "node-foreign-object-div",
      data: (d) => [d],
    });

    this.restyleForeignObjectElements();
    /*
     * End : node content with template
     */

    /*
     * Start : photos
     */
    // Defined node images wrapper group
    const nodeImageGroups = nodeEnter.patternify({
      tag: "g",
      selector: "node-image-group",
      data: (d) => [d],
    });

    // Add background rectangle for node image
    nodeImageGroups.patternify({
      tag: "rect",
      selector: "node-image-rect",
      data: (d) => [d],
    });
    /*
     * End : photos
     */

    /*
     * Start : close button
     */
    const nodeCloseButtonGroups = nodeEnter
      .patternify({
        tag: "g",
        selector: "node-button-close",
        data: (d) => [d],
      })
      .on("click", (d, e, tag) => this.onButtonCloseClick(d, e, tag));

    // Add expand collapse button circle
    nodeCloseButtonGroups.patternify({
      tag: "circle",
      selector: "node-button-close-circle",
      data: (d) => [d],
    });

    // Add button text
    nodeCloseButtonGroups
      .patternify({
        tag: "text",
        selector: "node-button-close-text",
        data: (d) => [d],
      })
      .attr("pointer-events", "none");
    // treeData.descendants().map((d) => {
    const rootNode = treeData.descendants().find((d) => d.data.rawData.id === attrs.rootNode.positionCode);
    if (rootNode) {
      attrs.rootNode.nodeId = rootNode.data.nodeId;
      attrs.rootNode.id = rootNode.data.rawData.id;
    }

    const rootNodeIdNumber = rootNode ? parseInt(rootNode.data.nodeId.substr(5), 10) : -1;

    // Move node button group to the desired position
    nodeUpdate
      .select(".node-button-close")
      .attr("transform", ({ data }) => `translate(${data.width / 2}, -${this.absoluteNodeTreelineDistance})`)
      .attr("opacity", ({ data }) => {
        const position = parseInt(data.nodeId.substr(5), 10);
        // if child of root, hide
        return position > 0 ? 1 : 0;
      });

    // Restyle node button circle
    nodeUpdate
      .select(".node-button-close-circle")
      .attr("r", 16)
      .attr("stroke-width", ({ data }) => data.borderWidth || attrs.strokeWidth)
      .attr("fill", attrs.backgroundColor)
      .attr("stroke", ({ borderColor }) => borderColor);

    // Restyle button texts
    nodeUpdate
      .select(".node-button-close-text")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("fill", attrs.defaultTextFill)
      .attr("font-size", 16)
      .text("X")
      .attr("y", isEdge() ? 10 : 2);
    /*
     * End : close button
     */

    /*
     * Start : expand/collapse button
     */
    // Add Node button circle's group (expand-collapse button)
    const nodeExpandButtonGroups = nodeEnter
      .patternify({
        tag: "g",
        selector: "node-button-g",
        data: (d) => [d],
      })
      .on("click", (d, e, tag) => this.onButtonClick(d, e, tag));

    // Add expand collapse button circle
    nodeExpandButtonGroups.patternify({
      tag: "circle",
      selector: "node-button-circle",
      data: (d) => [d],
    });

    // Add button text
    nodeExpandButtonGroups
      .patternify({
        tag: "text",
        selector: "node-button-text",
        data: (d) => [d],
      })
      .attr("pointer-events", "none");

    // Move node button group to the desired position
    nodeUpdate
      .select(".node-button-g")
      .attr("transform", ({ data }) => `translate(0,${data.height - this.absoluteNodeTreelineDistance})`)
      .attr("opacity", (data) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { children, _children } = data;
        if (children || _children) {
          let showButton = true;
          if (data.parent) {
            const nbExpanded = data.parent.children.map((c) => (c.data.rawData.expanded ? 1 : 0)).reduce((acc, cur) => acc + cur, 0);
            if (nbExpanded > MAX_CHILDREN) {
              showButton = false;
            }
          }

          return showButton ? 1 : 0;
        }
        return 0;
      });

    // Restyle node button circle
    nodeUpdate
      .select(".node-button-circle")
      .attr("r", 16)
      .attr("stroke-width", ({ data }) => data.borderWidth || attrs.strokeWidth)
      .attr("fill", attrs.backgroundColor)
      .attr("stroke", ({ borderColor }) => borderColor);

    // Restyle button texts
    nodeUpdate
      .select(".node-button-text")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("fill", attrs.defaultTextFill)
      .attr("font-size", ({ children }) => {
        if (children) return 16;
        return 16;
      })
      .text(({ children }) => {
        if (children) return "-";
        return "+";
      })
      .attr("y", isEdge() ? 10 : 0);
    /*
     * End : expand/collapse button
     */

    /*
     * Start : manager expand button
     */
    // Add Node button circle's group (expand-collapse button)
    const nodeManagerButtonGroups = nodeEnter
      .patternify({
        tag: "g",
        selector: "node-button-g-manager",
        data: (d) => [d],
      })
      .on("click", (d, e, tag) => this.onButtonManagerClick(d, e, tag));

    // Add expand collapse button circle
    nodeManagerButtonGroups.patternify({
      tag: "circle",
      selector: "node-button-circle-manager",
      data: (d) => [d],
    });

    // Add button text
    nodeManagerButtonGroups
      .patternify({
        tag: "text",
        selector: "node-button-text-manager",
        data: (d) => [d],
      })
      .attr("pointer-events", "none");

    const topNode = treeData.descendants() && Array.isArray(treeData.descendants()) ? treeData.descendants()[0] : undefined;
    // Move node button group to the desired position
    nodeUpdate
      .select(".node-button-g-manager")
      .attr("transform", () => `translate(0,-${this.absoluteNodeTreelineDistance})`)
      .attr("opacity", (node) => {
        if (!topNode || !topNode.data || !topNode.data.rawData) {
          return 0;
        }
        const { data } = node;
        const position = parseInt(data.nodeId.substr(5), 10);
        const { parentId, id } = data.rawData;
        if (parentId && topNode.data.rawData.id === id) {
          return 1;
        }
        if (parentId && (position <= rootNodeIdNumber || rootNodeIdNumber === -1)) {
          return 1;
        }
        return 0;
      });

    // Restyle node button circle
    nodeUpdate
      .select(".node-button-circle-manager")
      .attr("r", 16)
      .attr("stroke-width", ({ data }) => data.borderWidth || attrs.strokeWidth)
      .attr("fill", attrs.backgroundColor)
      .attr("stroke", ({ borderColor }) => borderColor);

    // Restyle button texts
    nodeUpdate
      .select(".node-button-text-manager")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("fill", attrs.defaultTextFill)
      .attr("font-size", ({ children }) => {
        if (children) return 16;
        return 16;
      })
      .text(({ parent }) => {
        return parent ? "-" : "+";
      })
      .attr("y", isEdge() ? 10 : 0);
    /*
     * End : expand/collapse button
     */

    // current node in a children list. Used to set max elements on one line
    let currentNode = -1;
    // used to check if the parent change
    let currentParent = { id: "" };
    numberOfChildren = 0;
    // Transition to the proper position for the node
    nodeUpdate
      .transition()
      .attr("opacity", 0)
      .duration(attrs.duration)
      .attr("transform", (__data) => {
        const { height, parent = {} } = __data || {};
        const { id = "", children } = parent || {};

        currentNode += 1;
        // if the current node is a children and the parent change
        if (currentParent.id !== id && children) {
          currentParent = parent;
          // reset children count
          currentNode = 0;
          numberOfChildren = children.length || 0;
        }

        // recalculate node y transform if not root
        if (currentParent.id) {
          __data.y =
            currentParent.y +
            currentParent.height +
            BOX_Y_SPACING +
            BOX_Y_SPACING * Math.floor(currentNode / MAX_CHILDREN) +
            height * Math.floor(currentNode / MAX_CHILDREN);
        }

        // If too many children, recalculate node x transform
        if (numberOfChildren > MAX_CHILDREN) {
          __data.x =
            -((MAX_CHILDREN - 1) / 2) * (NODE_WIDTH * attrs.separatorRatio + BOX_X_SPACING) +
            (NODE_WIDTH * attrs.separatorRatio + BOX_X_SPACING) * (currentNode % MAX_CHILDREN) +
            currentParent.x;
          // eslint-disable-next-line no-underscore-dangle
          __data.__children = [];
          __data.depth += currentNode % MAX_CHILDREN;
        }
        __data.translateX = __data.x;
        __data.translateY = __data.y;
        return `translate(${__data.x},${__data.y})`;
      })
      .attr("opacity", 1);

    // Move images to desired positions
    nodeUpdate.selectAll(".node-image-group").attr("transform", ({ imageWidth, width }) => {
      const iX = -imageWidth / 2 - width / 2;
      const iY = this.absoluteImageY; // Necessary otherwise take image format into account
      return `translate(${iX},${iY})`;
    });

    // Style node image rectangles
    nodeUpdate
      .select(".node-image-rect")
      .attr("fill", ({ id }) => `url(#${id})`)
      .attr("width", ({ imageWidth }) => imageWidth)
      .attr("height", ({ imageHeight }) => imageHeight)
      .attr("stroke", ({ imageBorderColor }) => imageBorderColor)
      .attr("stroke-width", ({ imageBorderWidth }) => imageBorderWidth)
      .attr("rx", ({ imageRx }) => imageRx)
      .attr("y", ({ imageCenterTopDistance }) => imageCenterTopDistance)
      .attr("x", ({ imageCenterLeftDistance }) => imageCenterLeftDistance)
      .attr("filter", ({ dropShadowId }) => dropShadowId);

    // Style node rectangles
    nodeUpdate
      .select(".node-rect")
      .attr("width", ({ data }) => data.width)
      .attr("height", ({ data }) => data.height)
      .attr("x", ({ data }) => -data.width / 2)
      .attr("y", -this.absoluteNodeTreelineDistance)
      .attr("rx", ({ data }) => data.borderRadius || 0)
      .attr("stroke-width", ({ data }) => data.borderWidth || attrs.strokeWidth)
      .attr("cursor", "pointer")
      .attr("stroke", () => "none")
      .style("fill", "#fff");

    // Remove any exiting nodes after transition
    const nodeExitTransition = nodesSelection
      .exit()
      .attr("opacity", 1)
      .transition()
      .duration(attrs.duration)
      .attr("transform", () => `translate(${x},${y})`)
      .on("end", function end() {
        d3.select(this).remove();
      })
      .attr("opacity", 0);

    // On exit reduce the node rects size to 0
    nodeExitTransition.selectAll(".node-rect").attr("width", 10).attr("height", 10).attr("x", 0).attr("y", 0);

    // On exit reduce the node image rects size to 0
    nodeExitTransition
      .selectAll(".node-image-rect")
      .attr("width", 10)
      .attr("height", 10)
      .attr("x", ({ width }) => width / 2)
      .attr("y", ({ height }) => height / 2);

    // Store the old positions for transition.
    nodes.forEach((d) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    // --------------------------  LINKS ----------------------
    // Get links selection
    numberOfChildren = attrs.rawData.children.length || 0;

    const linkSelection = attrs.centerG.selectAll("path.link").data(links, ({ id }) => id);

    // Enter any new links at the parent's previous position.
    const linkEnter = linkSelection
      .enter()
      .insert("path", "g")
      .attr("class", "link")
      .attr("d", (d) => {
        const o = {
          x: x0,
          y: y0,
        };
        return diagonal(o, o, numberOfChildren <= MAX_CHILDREN, d);
      });

    // Get links update selection
    const linkUpdate = linkEnter.merge(linkSelection);

    // Styling links
    linkUpdate
      .attr("fill", "none")
      .attr("stroke-width", ({ data }) => data.connectorLineWidth || 2)
      .attr("stroke", ({ data }) => {
        if (data.connectorLineColor) {
          return rgbaObjToColor(data.connectorLineColor);
        }
        return "green";
      })
      .attr("stroke-dasharray", ({ data }) => {
        if (data.dashArray) {
          return data.dashArray;
        }
        return "";
      });

    // Transition back to the parent element position
    linkUpdate
      .transition()
      .duration(attrs.duration)
      .attr("d", (d) => diagonal(d, d.parent, numberOfChildren <= MAX_CHILDREN, d));

    // Remove any  links which is exiting after animation
    linkSelection
      .exit()
      .transition()
      .duration(attrs.duration)
      .attr("d", (d) => {
        const o = {
          x,
          y,
        };
        return diagonal(o, o, numberOfChildren <= MAX_CHILDREN, d);
      })
      .remove();

    // --------------------------  LEGEND ----------------------

    // find start Y
    let legendY = 0;
    nodes.forEach((d) => {
      legendY = Math.max(legendY, d.y + d.height);
    });

    const legendWidth = currentTemplate === "next" ? nextLegendWidth : omrLegendWidth;

    const legendNode = attrs.centerG
      .patternify({
        tag: "g",
        selector: "legend-node",
        data: (d) => [d],
      })
      .attr("width", legendWidth)
      .attr("height", legendHeight);
    legendNode.attr("transform", () => `translate(${0},${legendY + legendTransformY})`);

    // Add background rectangle for the nodes
    legendNode
      .patternify({
        tag: "rect",
        selector: "legend-node",
        data: (d) => [d],
      })
      .style("fill", "#fOO")
      .attr("x", -legendWidth / 2)
      .attr("y", -legendHeight / 2);

    // Add foreignObject element inside rectangle
    const legendForeignObject = legendNode.patternify({
      tag: "foreignObject",
      selector: "legend-node-object",
      data: (d) => [d],
    });

    // Add foreign object
    const legendDiv = legendForeignObject.patternify({
      tag: "xhtml:div",
      selector: "legend-node-foreign-object-div",
      data: (d) => [d],
    });

    legendForeignObject
      .attr("width", legendWidth)
      .attr("height", legendHeight)
      .attr("x", -legendWidth / 2)
      .attr("y", -legendHeight / 2);
    legendDiv.style("width", `${legendWidth}px`).style("height", `${legendHeight}px`);

    renderLegend(attrs.template, legendDiv, omrLegendWidth, nextLegendWidth);
  }

  restyleForeignObjectElements() {
    const attrs = this.getChartState();
    // const currentTemplate = attrs.template;
    attrs.svg
      .selectAll(".node-foreign-object")
      .attr("width", ({ width }) => width)
      .attr("height", ({ height }) => height)
      .attr("x", ({ width }) => -width / 2)
      .attr("y", () => -this.absoluteNodeTreelineDistance)
      .attr("id", ({ nodeId }) => nodeId);
    attrs.svg
      .selectAll(".node-foreign-object-div")
      .style("color", "white")
      .style("height", "100%")
      .html(({ data }) => data.template);
  }

  onButtonCloseClick(d, e, tag) {
    const attrs = this.getChartState();
    if (tag && tag[0] && tag[0].attributes) {
      const { opacity } = tag[0].attributes;
      if (opacity.value === "0") {
        return;
      }
    }

    const { data } = d;
    const rootNodeIdNumber = 0;
    const position = parseInt(data.nodeId.substr(5), 10);

    if (position <= rootNodeIdNumber) {
      return;
    }

    attrs.onRemoveNode(d);
  }

  onButtonUpdateClick(d) {
    const attrs = this.getChartState();
    // Retrieve node by node Id
    // const rawData = attrs.rawData.filter(({
    //                                         data
    //                                     }) => data.nodeId === d.data.nodeId)[0];
    attrs.onNodeUpdate(d.data.rawData, d.data.nodeId, this);
  }

  // Toggle children on click.
  onButtonClick(d, e, tag) {
    const attrs = this.getChartState();
    if (tag && tag[0] && tag[0].attributes) {
      const { opacity } = tag[0].attributes;
      if (opacity.value === "0") {
        return;
      }
    }

    // If children are expanded
    if (d.children) {
      // Collapse them
      d._children = d.children;
      d.children = null;

      // Set descendants expanded property to false
      this.setExpansionFlagToChildren(d, false);
      attrs.onShrinkClick(d);
    } else if (d._children) {
      // Expand children
      d.children = d._children;
      d._children = null;

      // Set each children as expanded
      d.children.forEach(({ data }) => {
        data.expanded = true;
      });
      attrs.onExpandClick(d);
    }

    // Redraw Graph
    this.update(d);
  }

  // Toggle children on click.
  onButtonManagerClick(d, e, tag) {
    const attrs = this.getChartState();
    if (tag && tag[0] && tag[0].attributes) {
      const { opacity } = tag[0].attributes;
      if (opacity.value === "0") {
        return;
      }
    }
    if (d.parent) {
      attrs.onShrinkManager(d);
    } else {
      attrs.onExpandManager(d);
    }
  }

  /* toggle(nodeId) {
    const attrs = this.getChartState();
    // Retrieve node by node Id
    const node = attrs.allNodes.filter(({ data }) => data.nodeId === nodeId)[0];

    // If node exists, set expansion flag
    if (node) {
      this.onButtonClick(node);
    }
  } */

  selectTemplate(templateName) {
    const attrs = this.getChartState();
    attrs.template = templateName;
    this.flattenData();
    this.render();
  }

  // This function changes `expanded` property to descendants
  setExpansionFlagToChildren({ data, children, _children }, flag) {
    // Set flag to the current property
    data.expanded = flag;
    // Loop over and recursively update expanded children's descendants
    if (children) {
      children.forEach((d) => {
        this.setExpansionFlagToChildren(d, flag);
      });
    }

    // Loop over and recursively update collapsed children's descendants
    if (_children) {
      _children.forEach((d) => {
        this.setExpansionFlagToChildren(d, flag);
      });
    }
  }

  // This function can be invoked via chart.setExpanded API, it expands or collapses particular node
  setExpanded(id, expandedFlag) {
    const attrs = this.getChartState();
    // Retrieve node by node Id
    const node = attrs.allNodes.filter(({ data }) => data.nodeId === id)[0];

    // If node exists, set expansion flag
    if (node) node.data.expanded = expandedFlag;

    // First expand all nodes
    attrs.root.children.forEach((d) => this.expand(d));

    // Then collapse all nodes
    attrs.root.children.forEach((d) => this.collapse(d));

    // Then expand only the nodes, which were previously expanded, or have an expand flag set
    attrs.root.children.forEach((d) => this.expandSomeNodes(d));

    // Redraw graph
    this.update(attrs.root);
  }

  // Method which only expands nodes, which have property set "expanded=true"
  expandSomeNodes(d) {
    // If node has expanded property set
    if (d.data.expanded) {
      // Retrieve node's parent
      let { parent } = d;

      // While we can go up
      while (parent) {
        // Expand all current parent's children
        if (parent._children) {
          parent.children = parent._children;
        }

        // Replace current parent holding object
        parent = parent.parent;
      }
    }

    // Recursivelly do the same for collapsed nodes
    if (d._children) {
      d._children.forEach((ch) => this.expandSomeNodes(ch));
    }

    // Recursivelly do the same for expanded nodes
    if (d.children) {
      d.children.forEach((ch) => this.expandSomeNodes(ch));
    }
  }

  // This function updates nodes state and redraws graph, usually after data change
  updateNodesState() {
    const attrs = this.getChartState();
    // Store new root by converting flat data to hierarchy
    try {
      attrs.root = d3
        .stratify()
        .id(({ nodeId }) => nodeId)
        .parentId(({ parentNodeId }) => parentNodeId)(attrs.data);
    } catch (e) {
      // console.log(`Failed to update nodes : ${e.message}`);
      return;
    }

    // Store positions, where children appear during their enter animation
    attrs.root.x0 = 0;
    attrs.root.y0 = 0;

    // Store all nodes in flat format (although, now we can browse parent, see depth e.t.c. )
    attrs.allNodes = attrs.layouts.treemap(attrs.root).descendants();

    // Store direct and total descendants count
    attrs.allNodes.forEach((d) => {
      Object.assign(d.data, {
        directSubordinates: d.children ? d.children.length : 0,
        totalSubordinates: d.descendants().length - 1,
      });
    });

    if (attrs.root.children) {
      // Expand all nodes first
      attrs.root.children.forEach(this.expand);

      // Then collapse them all
      attrs.root.children.forEach((d) => this.collapse(d));

      // Then only expand nodes, which have expanded proprty set to true
      attrs.root.children.forEach((ch) => this.expandSomeNodes(ch));
    }

    // Redraw Graphs
    // this.update(attrs.root);
  }

  // Function which collapses passed node and it's descendants
  collapse(d) {
    if (d.children) {
      d._children = d.children;
      d._children.forEach((ch) => this.collapse(ch));
      d.children = null;
    }
  }

  // Function which expands passed node and it's descendants
  expand(d) {
    if (d._children) {
      d.children = d._children;
      d.children.forEach((ch) => this.expand(ch));
      d._children = null;
    }
  }

  // Zoom handler function
  zoomed() {
    const attrs = this.getChartState();
    const { chart } = attrs;

    // Get d3 event's transform object
    const { transform } = d3.event;

    // Store it
    attrs.lastTransform = transform;
    attrs.onChartMove(transform);

    // Reposition and rescale chart accordingly
    chart.attr("transform", transform);

    // Apply new styles to the foreign object element
    if (isEdge()) {
      this.restyleForeignObjectElements();
    }
  }

  flattenData() {
    let parentNodeId = null;
    let maxHeight = -1;

    const attrs = this.getChartState();
    attrs.data = d3
      .hierarchy(attrs.rawData)
      .descendants()
      .map((d, i) =>
        Object.assign(d, {
          // id: "node-" + i /*DOM.uid().id*/,
          nodeId: `node-${i}`,
        })
      )
      .map((d) => {
        const height = computeHeight(attrs.template, d.data);
        if (height > maxHeight) {
          maxHeight = height;
        }
        return Object.assign(d.data, {
          // id: d.id,
          nodeId: d.nodeId,
          // parentId: d.parent && d.parent.id,
          parentNodeId: d.parent && d.parent.nodeId,
          isClosed: d.data.isClosed || false,
        });
      })
      .map((d) => {
        const { expanded = false } = d || {};
        if (parentNodeId !== d.parentNodeId) {
          parentNodeId = d.parentNodeId;
        }

        const width = NODE_WIDTH;

        const cornerShape = "ROUNDED";
        const nodeImageWidth = 100;
        const nodeImageHeight = 100;
        const centerTopDistance = 0;
        const centerLeftDistance = 0;
        const borderRadius = 0;
        // const expanded = false; // d.id=="O-6"

        const titleMarginLeft = nodeImageWidth / 2 + 20 + centerLeftDistance;
        const colorBorderGray = "darkgray";

        const readinessColor = {
          [READINESS.YEAR]: COLORS.GREEN,
          [READINESS.MID]: COLORS.ORANGE,
          [READINESS.LONG]: COLORS.PURPLE,
        };
        // Data fix
        d.jobTenure = d.jobTenure || "";
        d.title = d.title || "N/A";
        d.type = d.type || "employee";
        // ---

        return {
          nodeId: d.nodeId,
          parentNodeId: d.parentNodeId,
          width,
          height: computeHeight(attrs.template, d),
          borderWidth: 1,
          borderRadius,
          borderColor: {
            red: 169,
            green: 169,
            blue: 169,
            alpha: 1,
          },
          backgroundColor: {
            red: 255, // 0,
            green: 255, // 81,
            blue: 255, // 90,
            alpha: 1,
          },
          nodeImage: {
            url: d.photo ? d.photo : defaultImage,
            width: nodeImageWidth,
            height: nodeImageHeight,
            centerTopDistance,
            centerLeftDistance,
            cornerShape,
            shadow: true,
            borderWidth: 0,
            borderColor: {
              red: 19,
              green: 123,
              blue: 128,
              alpha: 1,
            },
          },
          nodeIcon: {
            icon: "https://to.ly/1yZnX",
            size: 30,
          },
          template: renderNode(attrs.template, d, titleMarginLeft, borderRadius, readinessColor, colorBorderGray),
          connectorLineColor: {
            red: 85,
            green: 85,
            blue: 85,
            alpha: 1,
          },
          connectorLineWidth: 1,
          dashArray: "",
          expanded,
          rawData: d,
        };
      });
    return this;
  }

  exportImage(fileName) {
    const attrs = this.getChartState();

    const svg = attrs.svg.node();
    // const svgChart = attrs.svg.selectAll(".chart").node();

    // mask useless
    attrs.svg.selectAll(".node-button-close").attr("opacity", 0);
    attrs.svg.selectAll(".node-button-g").attr("opacity", 0);
    attrs.svg.selectAll(".node-button-g-manager").attr("opacity", 0);

    // 480p
    // let width = 720, height = 480;
    // 720p
    // let width = 1280, height = 720;
    // 1080p
    // let width = 1980, height = 1080;
    // 4k
    // let width = 3840, height = 2160;
    // 8k
    // let width = 7680, height = 4320;

    // real
    // const multFactor = 4;
    const width = svg.width.baseVal.value * 3;
    const height = svg.height.baseVal.value * 3;
    // eslint-disable-next-line
    svgString2Image(getSVGString(svg), width, height, "png", save); // passes Blob and filesize String to the callback

    // mask useless
    attrs.svg.selectAll(".node-button-close").attr("opacity", 1);
    attrs.svg.selectAll(".node-button-g").attr("opacity", 1);
    attrs.svg.selectAll(".node-button-g-manager").attr("opacity", 1);
    function save(dataBlob) {
      // eslint-disable-next-line
      saveAs(dataBlob, fileName ? `${fileName}.png` : "Org lCharts.png"); // FileSaver.js function
    }
    this.update(attrs.root);
  }
}

export default TreeChart;
