import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Check, X } from "react-feather";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { RootState } from "store/types";
import actions from "store/actions";
import colors from "assets/style/colors";
import { breakpoints } from "assets/style/breakpoints";
import Button from "components/button/Button";
import { User } from "types/common.types";
import UserForm from "./UserForm";
import { TAB_KEYS } from "./form.config";
import { prepareForm } from "./editPanelUtils";

type ContainerProps = {
  hide: boolean;
};

const Container = styled.div`
  position: absolute;
  box-sizing: border-box;
  box-shadow: -1px 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  right: 0px;
  top: 10px;
  max-width: 685px;
  @media only screen and (min-width: ${breakpoints.xl + 1}px) {
    width: 40%;
  }
  @media only screen and (min-width: ${breakpoints.lg + 1}px) and (max-width: ${breakpoints.xl}px) {
    width: 50%;
  }
  @media only screen and (min-width: ${breakpoints.md + 1}px) and (max-width: ${breakpoints.lg}px) {
    width: 55%;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    width: 60%;
  }
  background-color: ${colors.lightGrey};
  max-height: 95%;
  min-height: ${(props: ContainerProps) => (props.hide ? "0" : "95%")};
  opacity: ${(props: ContainerProps) => (props.hide ? "0" : "1")};
  display: ${(props: ContainerProps) => (props.hide ? "none" : "flex")};
  overflow: hidden;
  flex-direction: column;
  z-index: 10;
`;

const FormContainer = styled.div`
  overflow-y: scroll;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Header = styled.div`
  background-color: white;
  display: flex;
`;

type TabSelectorProps = {
  selected: boolean;
};

const TabSelector = styled.span`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 12px 6px 12px;
`;

const TabSelectorText = styled.span`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  cursor: pointer;
  ${(props: TabSelectorProps) => props.selected && "border-bottom: 2px solid #c4c4c4; padding-bottom: 4px;"}

  &:hover {
    padding-bottom: 4px;
    border-bottom: 2px solid #c4c4c4;
  }
`;

const tabs = [TAB_KEYS.POSITION, TAB_KEYS.EMPLOYEE, TAB_KEYS.TEAM];

const EditPanel: React.FunctionComponent = () => {
  const userToEdit = useSelector((state: RootState) => state.chart.userToEdit);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(TAB_KEYS.POSITION);

  const selectUserToEdit = (user: User) => dispatch(actions.chart.selectUserToEdit(user));
  const updateUser = (user: User) => dispatch(actions.chart.updateUser(user));

  useEffect(() => {
    setSelected(TAB_KEYS.POSITION);
  }, [userToEdit?.id]);

  const intl = useIntl();
  const formRef = useRef();

  return (
    <Container hide={!userToEdit}>
      <Header>
        {tabs.map((t) => (
          <TabSelector key={t} onClick={() => setSelected(t)}>
            <TabSelectorText selected={selected === t}>{intl.formatMessage({ id: `form.user.tabs.${t}` })}</TabSelectorText>
          </TabSelector>
        ))}
      </Header>
      <FormContainer>
        {userToEdit && (
          <UserForm
            selectedUser={userToEdit}
            onSubmit={(values: User) => {
              const formattedValues = prepareForm(userToEdit, values);
              updateUser(formattedValues);
            }}
            formRef={formRef}
            selectedTab={selected}
          />
        )}
      </FormContainer>
      <ButtonContainer>
        <Button
          onClick={() => selectUserToEdit(null)}
          type="button"
          variant="white"
          value={intl.formatMessage({ id: "form.user.cancel" })}
          renderIcon={() => <X size={24} />}
          flex="1"
          fontSize={18}
        />
        <Button
          onClick={() => {
            if (formRef.current) {
              // @ts-ignore
              formRef.current.handleSubmit();
            }
          }}
          type="submit"
          variant="black"
          value={intl.formatMessage({ id: "form.user.apply" })}
          renderIcon={() => <Check size={24} />}
          flex="1"
          fontSize={18}
        />
      </ButtonContainer>
    </Container>
  );
};

export default EditPanel;
