const form = {
  "form.error.max150": "Must be 150 characters or less",
  "form.error.required": "This field is required",
  "form.login.username": "mail@lvmh.com",
  "form.login.password": "**********",
  "form.user.formTitle": "Update information",
  "form.user.lastName": "Last Name",
  "form.user.firstName": "First Name",
  "form.user.id": "POSITION ID : {id}",
  "form.user.company": "Company",
  "form.user.readiness": "Readiness",
  "form.user.position": "Position",
  "form.user.potential": "Potential",
  "form.user.jobTitle": "Title",
  "form.user.title": "Position",
  "form.user.jobTenure": "Position start date (DD/MM/YYYY)",
  "form.user.groupStartDate": "Group start date (DD/MM/YYYY)",
  "form.user.futurA": "FuturA",
  "form.user.yes": "Yes",
  "form.user.no": "No",
  "form.user.maison": "Company",
  "form.user.employees": "Employees",
  "form.user.employees.button": "Add Employee",
  "form.user.employees.more": "More information",
  "form.user.employees.less": "Less information",
  "form.user.successor": "SUCCESSOR {index}",
  "form.user.successor.empty": "NO SUCCESSOR",
  "form.user.employee.empty": "NO EMPLOYEE",
  "form.user.employee": "EMPLOYEE {index}",
  "form.user.potentialSuccessors": "Potential Successors",
  "form.user.potentialSuccessor.button": "Add potential successor",
  "form.user.nextSteps": "Next Steps",
  "form.user.nextSteps.button": "Add next step",
  "form.user.nextSteps.list": "Next step {id}",
  "form.user.retentionRisk": "High Retention Risk",
  "form.user.retRisk": "Retention Risk",
  "form.user.criticalExpert": "Critical Expert",
  "form.user.children": "Positions",
  "form.user.children.sequence": "Position sequence",
  "form.user.children.button": "Add position",
  "form.user.display.omr.team": "Display OMR information",
  "form.user.display.omr.manager": "Display OMR information",
  "form.user.hide.team.photo": "Hide photo",
  "form.user.hide.manager.photo": "Hide photo",
  "form.user.isClosed": "Display / Hide",
  "form.user.isClosed.hide": "Hide",
  "form.user.isClosed.display": "Display",
  "form.user.talentStatus": "Talent Status",
  "form.user.keyPosition": "Key Position",
  "form.user.submit": "Save information",
  "form.user.apply": "APPLY",
  "form.user.cancel": "CANCEL",
  "form.user.performance": "Performance",
  "form.user.performance.NO": "N/A",
  "form.user.performance.NOT_MEET": "Does not meet expectations",
  "form.user.performance.PARTIALLY": "Partially achieves",
  "form.user.performance.ACHIEVED": "Achieves objectives",
  "form.user.performance.EXCEEDED": "Exceeds",
  "form.user.performance.OUTSTANDING": "Outstanding",
  "form.user.teams": "Team",
  "form.user.tabs.position": "position",
  "form.user.tabs.employee": "employee",
  "form.user.tabs.team": "team",
};

export default form;
