import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Search, XCircle } from "react-feather";
import UseAnimations from "react-useanimations";
import loading from "react-useanimations/lib/loading";
import api from "api";
import colors from "assets/style/colors";
import actions from "store/actions";
import { Employee, UserSearch } from "types/common.types";
import { ERROR_TYPES, extractApiError, MESSAGE } from "utils/error.utils";
import { useOnClickOutside } from "components/hooks/useOnClickOutside";
import { formatName } from "components/organigram/lib/js/organigram.render";
import useKeyPress from "components/hooks/useKeyPress";
import Row from "components/surface/Row";
import Col from "components/surface/Col";
import { triggerEvent } from "gtm/gtmKeys";
import { RootState } from "../../store/types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ResultContainer = styled.div`
  position: absolute;
  top: 110%;
  width: 100%;
  border-radius: 6px;
  background: white;
  max-height: 50vh;
  overflow: auto;
  z-index: 15;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

type InputSearchProps = {
  selected: boolean;
};

const InputSearch = styled.input`
  border-radius: 0;
  border: 0;
  background-color: #fff;
  font-size: 18px;
  color: #495057;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${(props: InputSearchProps) =>
    props.selected ? "outline-color: black; outline-style: auto; outline-width: 1px; padding-right: 40px;" : "padding-right: 12px;"}

  &:focus + ${ResultContainer} {
    display: block;
  }
`;

const XContainer = styled.div`
  position: absolute;
  cursor: pointer;
  right: 12px;
  top: 10px;
`;

const InputSearchContainer = styled.div`
  border-radius: 0;
  border: 0;
  height: 45px;
  position: relative;
  width: 100%;

  &:focus + ${ResultContainer} {
    display: block;
  }
`;

type SubmitButtonProps = {
  empty: boolean;
};

const SubmitButton = styled.span`
  border-radius: 0;
  height: 100%;
  background-color: #050c17;
  color: ${(props: SubmitButtonProps) => (props.empty ? "white" : "white")};
  cursor: pointer;
  font-size: 18px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    padding: 0 6px;
  }
  & > div {
    padding: 0 6px;
  }
  & > span {
    display: none;
  }
  @media only screen and (min-width: 992px) {
    & > span {
      display: flex;
    }
  }
`;

type SearchResultProps = {
  active: boolean;
};

const SearchResult = styled.div`
  padding: 8px 0.75rem;
  cursor: pointer;
  ${(props: SearchResultProps) => props.active && `background: ${colors.lightGrey};`}
`;

let searchHandler: any = null;

const SearchBar: React.FunctionComponent = () => {
  const intl = useIntl();
  const refInput = useRef();
  const ref = useRef();
  const dispatch = useDispatch();
  const setSearchInput = (searchBar: string) => dispatch(actions.chart.setSearchBar(searchBar));
  const searchInput = useSelector((state: RootState) => state.chart.searchBar);
  const [searchResult, setSearchResult] = useState<UserSearch[]>([]);
  const [open, setOpen] = useState(false);
  // fetching === id of finished timeout
  const [fetching, setFetching] = useState(0);

  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  useOnClickOutside(ref, () => setOpen(false));

  const setRootUser = (user: UserSearch) => dispatch(actions.chart.setRootUser(user));
  const showError = (e: any) => dispatch(actions.error.showError(e));

  const onValidate = (u: UserSearch) => {
    if (!u) {
      return;
    }
    setOpen(false);
    setSearchInput(
      `${formatName(
        u.employees && u.employees[0] ? u.employees[0].firstName : "",
        u.employees && u.employees[0] ? u.employees[0].lastName : ""
      )} - ${u.positionTitle} ${u.subsidiary ? `- ${u.subsidiary}` : ""}`.trim()
    );
    triggerEvent("chartLoaded");
    setRootUser(u);
    setCursor(0);
  };

  useEffect(() => {
    if (searchResult.length && downPress && open) {
      setCursor((prevState) => (prevState < searchResult.length - 1 ? prevState + 1 : prevState));
    }
    // eslint-disable-next-line
  }, [downPress]);

  useEffect(() => {
    if (searchResult.length && upPress && open) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
    // eslint-disable-next-line
  }, [upPress]);

  useEffect(() => {
    if (searchResult.length && enterPress && open) {
      onValidate(searchResult[cursor]);
    }
    // eslint-disable-next-line
  }, [enterPress]);

  useEffect(() => {
    if (searchResult.length && hovered) {
      setCursor(searchResult.indexOf(hovered));
    }
    // eslint-disable-next-line
  }, [hovered]);

  const updateResult = (input: string, data: Array<UserSearch>) => {
    // Keep only one employee per array
    const res: Array<UserSearch> = [];
    if (Array.isArray(data)) {
      data.forEach((d: UserSearch) =>
        d.employees && Array.isArray(d.employees) && d.employees.length > 0
          ? d.employees.forEach((p: Employee) => {
              res.push({ ...d, employees: [p] });
            })
          : res.push(d)
      );
    }
    setSearchResult(res);
  };

  const onChangeSearchInput = async (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    setSearchInput(input);
    if (searchHandler) {
      clearTimeout(searchHandler);
    }

    searchHandler = setTimeout(async () => {
      const id = searchHandler;
      if (input) {
        try {
          const result = await api.search.byTerm(
            input
              .replace(/Vacant -/g, "")
              .split("&")
              .map((t) => t.trim())
              .join(" ")
              .split("-")
              .map((t) => t.trim())
              .join(" ")
          );
          if (result && result.data) {
            updateResult(input, result.data);
            setOpen(true);
          }
        } catch (err: any) {
          if (extractApiError(err)) {
            showError({
              type: ERROR_TYPES.API,
              details: extractApiError(err),
              msg: MESSAGE.DEFAULT,
            });
          } else {
            showError(err);
          }
        } finally {
          setFetching(id);
        }
      } else {
        setSearchResult([]);
      }
    }, 500);
  };

  return (
    <Container>
      <Row padding="0 0 8px 0">Search by name, position or position ID</Row>
      <Row>
        <Col xs={8} lg={9} padding="0 0 0 0">
          <InputSearchContainer ref={ref}>
            <InputSearch
              selected={open}
              type="text"
              ref={refInput}
              placeholder={intl.formatMessage({ id: "common.searchBar.placeholder" })}
              value={searchInput}
              onFocus={() => {
                triggerEvent("searchBarLaunched");
                setOpen(true);
              }}
              onChange={onChangeSearchInput}
            />
            {open && searchInput && (
              <XContainer
                onClick={() => {
                  setSearchInput("");
                  setSearchResult([]);
                  if (refInput && refInput.current) {
                    // @ts-ignore
                    refInput.current.focus();
                  }
                }}
              >
                <XCircle size={24} />
              </XContainer>
            )}
            {searchResult && searchResult.length > 0 && open && (
              <ResultContainer>
                {searchResult.map((r, i) => (
                  <SearchResult
                    key={`${r.positionCode}-${i}`}
                    active={i === cursor}
                    onClick={() => onValidate(r)}
                    onMouseEnter={() => setHovered(r)}
                    onMouseLeave={() => setHovered(undefined)}
                  >{`${formatName(
                    r.employees && r.employees[0] ? r.employees[0].firstName : "",
                    r.employees && r.employees[0] ? r.employees[0].lastName : ""
                  )} - ${r.positionTitle} ${r.subsidiary ? `- ${r.subsidiary}` : ""}`}</SearchResult>
                ))}
              </ResultContainer>
            )}
          </InputSearchContainer>
        </Col>
        <Col xs={4} lg={3} padding="0 0 0 0">
          <SubmitButton empty={searchInput === ""} onClick={() => onValidate(searchResult[cursor])}>
            {fetching !== (searchHandler || 0) ? <UseAnimations animation={loading} strokeColor="white" /> : <Search />}
            <span>{intl.formatMessage({ id: "common.searchBar.submit" })}</span>
          </SubmitButton>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchBar;
