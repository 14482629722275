import { triggerEvent } from "gtm/gtmKeys";
import { User, Successor, Employee } from "types/common.types";

export const capitalize = (string: string) => {
  return string?.toUpperCase() || "";
};
export const capitalizeFirstLetter = (string: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};
export const capitalizeFirstLetters = (string: string) => {
  return string ? string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()) : "";
};

const compareSuccessionPart = (oldPart: Array<Successor>, newPart: Array<Successor>): Array<Successor> => {
  const returnedSuccessionPart = newPart.map((successor) => {
    return {
      ...successor,
      employee: {
        ...successor.employee,
        firstName: capitalizeFirstLetter(successor.employee.firstName),
        lastName: capitalize(successor.employee.lastName),
        maison: capitalizeFirstLetters(successor.employee.maison),
      },
    };
  });

  const removedEmployee = oldPart.filter(
    (oldE) =>
      !returnedSuccessionPart.find(
        (elem) => elem.employee.firstName === oldE.employee.firstName && elem.employee.lastName === oldE.employee.lastName
      )
  );
  const updatedEmployee = oldPart.filter((oldE) =>
    returnedSuccessionPart.find(
      (elem) =>
        elem.employee.firstName === oldE.employee.firstName &&
        elem.employee.lastName === oldE.employee.lastName &&
        (elem.employee.maison !== oldE.employee.maison || elem.readiness !== oldE.readiness)
    )
  );

  const newEmployee = returnedSuccessionPart.filter(
    (newE) =>
      !oldPart.find((elem) => elem.employee.firstName === newE.employee.firstName && elem.employee.lastName === newE.employee.lastName)
  );

  if (removedEmployee.length > 0) {
    triggerEvent("potentialSuccessorRemoved", removedEmployee.length);
  }
  if (updatedEmployee.length > 0) {
    triggerEvent("potentialSuccessorUpdated", updatedEmployee.length);
  }
  if (newEmployee.length > 0) {
    triggerEvent("potentialSuccessoradded", newEmployee.length);
  }
  return returnedSuccessionPart;
};

const compareEmployeePart = (oldPart: Array<Employee>, newPart: Array<Employee>): Array<Employee> => {
  const returnedEmployeePart = newPart.map((employee) => {
    return {
      ...employee,
      firstName: capitalizeFirstLetter(employee.firstName),
      lastName: capitalize(employee.lastName),
      nextSteps: employee.nextSteps?.map((nextStep) => {
        return {
          ...nextStep,
          jobTitle: capitalizeFirstLetters(nextStep.jobTitle),
          maison: capitalizeFirstLetters(nextStep.maison),
        };
      }),
    };
  });

  const newEmployee = returnedEmployeePart.filter(
    (newE) => !oldPart.find((elem) => elem.firstName === newE.firstName && elem.lastName === newE.lastName)
  );

  let removedEmployee = 0;
  let updatedEmployeeCriticalExpert = 0;
  let updatedEmployeeKeyContributor = 0;
  let updatedEmployeeFuturA = 0;
  let updatedEmployeePotential = 0;
  let updatedEmployeePerformance = 0;
  let updatedEmployeeRetentionRisk = 0;
  let nextStepsAdded = 0;
  let nextStepsRemoved = 0;

  oldPart.forEach((oldE) => {
    const newE = returnedEmployeePart.find((elem) => elem.firstName === oldE.firstName && elem.lastName === oldE.lastName);
    if (newE) {
      if (newE.company !== oldE.company) {
        updatedEmployeeKeyContributor += 1;
      }
      if (newE.futurA !== oldE.futurA) {
        updatedEmployeeFuturA += 1;
      }
      if (newE.criticalExpert !== oldE.criticalExpert) {
        updatedEmployeeCriticalExpert += 1;
      }
      if (newE.potential !== oldE.potential) {
        updatedEmployeePotential += 1;
      }
      if (newE.performance !== oldE.performance) {
        updatedEmployeePerformance += 1;
      }
      if (newE.retentionRisk !== oldE.retentionRisk) {
        updatedEmployeeRetentionRisk += 1;
      }
    } else {
      removedEmployee += 1;
    }
    const currentEmployeeNextStepsAdd =
      (newE &&
        newE.nextSteps?.filter(
          (n) => !oldE.nextSteps?.find((o) => o.jobTitle === n.jobTitle && o.maison === n.maison && o.readiness === n.readiness)
        )) ||
      [];

    const currentEmployeeNextStepsRemoved =
      oldE.nextSteps?.filter(
        (o) => !newE.nextSteps?.find((n) => o.jobTitle === n.jobTitle && o.maison === n.maison && o.readiness === n.readiness)
      ) || [];

    nextStepsAdded += currentEmployeeNextStepsAdd.length;
    nextStepsRemoved += currentEmployeeNextStepsRemoved.length;
  });
  if (newEmployee.length > 0) {
    triggerEvent("employeeAdd", newEmployee.length);
  }

  if (removedEmployee > 0) {
    triggerEvent("employeeRemoved", removedEmployee);
  }

  if (updatedEmployeeFuturA > 0) {
    triggerEvent("futurAUpdated", updatedEmployeeFuturA);
  }
  if (updatedEmployeeKeyContributor > 0) {
    triggerEvent("keyContributorChanged", updatedEmployeeKeyContributor);
  }
  if (updatedEmployeeCriticalExpert > 0) {
    triggerEvent("criticalExpertUpdated", updatedEmployeeCriticalExpert);
  }
  if (updatedEmployeePotential > 0) {
    triggerEvent("potentialUpdated", updatedEmployeePotential);
  }
  if (updatedEmployeeRetentionRisk > 0) {
    triggerEvent("retentionRiskUpdated", updatedEmployeeRetentionRisk);
  }
  if (updatedEmployeePerformance > 0) {
    triggerEvent("performanceUpdated", updatedEmployeePerformance);
  }
  if (nextStepsAdded > 0) {
    triggerEvent("nextStepAdded", nextStepsAdded);
  }
  if (nextStepsRemoved > 0) {
    triggerEvent("nextStepRemoved", nextStepsRemoved);
  }

  return returnedEmployeePart;
};

const compareTeamPart = (oldPart: Array<User>, newPart: Array<User>): Array<User> => {
  const returnedTeamPart = newPart.map((child) => {
    return {
      ...child,
      title: capitalizeFirstLetters(child.title),
      employees: child.employees.map((employee) => {
        return {
          ...employee,
          firstName: capitalizeFirstLetter(employee.firstName),
          lastName: capitalize(employee.lastName),
          company: capitalizeFirstLetters(employee.company),
        };
      }),
    };
  });

  let teamMemberAdded = 0;
  let teamMemberRemoved = 0;
  let teamMemberPositionMoved = 0;
  oldPart.forEach((oldE, oldIndex) => {
    const newEIndex = returnedTeamPart.findIndex((elem) => elem.id === oldE.id);
    if (newEIndex >= 0) {
      if (newEIndex !== oldIndex) {
        teamMemberPositionMoved += 1;
      }
    } else {
      teamMemberRemoved += 1;
    }
  });

  teamMemberAdded = newPart.filter((newE) => !oldPart.find((o) => o.id === newE.id)).length;

  if (teamMemberAdded > 0) {
    triggerEvent("teamMemberAdd", teamMemberAdded);
  }
  if (teamMemberRemoved > 0) {
    triggerEvent("teamMemberRemoved", teamMemberRemoved);
  }
  if (teamMemberPositionMoved > 0) {
    triggerEvent("teamMemberPositionTitleMoved", teamMemberPositionMoved);
  }

  return returnedTeamPart;
};

export const prepareForm = (oldUser: User, newUser: User): User => {
  if (oldUser.keyPosition !== newUser.keyPosition) {
    triggerEvent("keyPositionUpdated");
  }
  return {
    ...newUser,
    title: capitalizeFirstLetters(newUser.title),
    employees: compareEmployeePart(oldUser.employees, newUser.employees),
    children: compareTeamPart(oldUser.children, newUser.children),
    potentialSuccessor: compareSuccessionPart(oldUser.potentialSuccessor, newUser.potentialSuccessor),
  };
};
