import styled from "styled-components";

type LabelGroupProps = {
  items?: number;
  justify?: string;
};

const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  ${(props: LabelGroupProps) => props.items && `width: calc(${100 / props.items}% - 8px)`};
  ${(props: LabelGroupProps) => props.justify && `justify-content: ${props.justify}`};
`;

export default LabelGroup;
