import React from "react";
import { Formik } from "formik";
import { Grid } from "react-flexbox-grid";
import styled from "styled-components";

import { User } from "types/common.types";
import { getInitialValues, TAB_KEYS } from "./form.config";
import FormReset from "./FormReset";
import PositionForm from "./PositionForm";
import EmployeeForm from "./EmployeeForm";
import TeamForm from "./TeamForm";

const Form = styled.form`
  padding: 6px 12px;
`;

type Props = {
  onSubmit: (form: User) => void;
  selectedUser: User;
  formRef: any;
  selectedTab: typeof TAB_KEYS.POSITION | typeof TAB_KEYS.EMPLOYEE | typeof TAB_KEYS.TEAM;
};

const UserForm: React.FunctionComponent<Props> = ({ selectedUser, onSubmit, formRef, selectedTab }) => {
  const initialValues = getInitialValues(selectedUser);

  const submit = (values: User) => {
    onSubmit(values);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submit} innerRef={formRef}>
      {({ values, handleSubmit }: any) => (
        <Form onSubmit={handleSubmit}>
          <FormReset selectedUser={selectedUser} />
          <Grid fluid>
            {selectedTab === TAB_KEYS.POSITION && <PositionForm values={values} selectedUser={selectedUser} />}
            {selectedTab === TAB_KEYS.EMPLOYEE && <EmployeeForm values={values} selectedUser={selectedUser} />}
            {selectedTab === TAB_KEYS.TEAM && <TeamForm values={values} selectedUser={selectedUser} />}
          </Grid>
          <input type="submit" style={{ display: "none" }} />
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
