// This function sets drop shadow ID to the passed object
import * as d3 from "d3";
import { BOX_Y_SPACING, MAX_CHILDREN } from "./organigram.constants";

// Generate custom diagonal - play with it here - https://observablehq.com/@bumbeishvili/curved-edges?collection=@bumbeishvili/work-components
export function diagonal(s: any, t: any, isOnLine: any, data: any): string {
  // Calculate some variables based on source and target (s,t) coordinates
  const { x } = s;
  let y = s.y + 0;
  const ex = t.x;
  let ey = t.y;

  // radius of corners
  let rdef = 0;

  const children = t.children ? t.children.length : 0;

  if (children <= MAX_CHILDREN) {
    const xrvs = ex - x < 0 ? -1 : 1;
    const yrvs = ey - y < 0 ? -1 : 1;

    let yFixed = ey;
    if (data.parent.data.rawData && t.height) {
      // const { rawData } = data.parent.data;

      /* if (rawData.potentialSuccessor && rawData.potentialSuccessor.length > 0 && (attr.template === "omr" || attr.template === "full")) {
        yFixed += rawData.potentialSuccessor.length * 20;
      }
      if (rawData.nextSteps && rawData.nextSteps.length > 0 && (attr.template === "next" || attr.template === "full")) {
        yFixed += rawData.nextSteps.length * 20;
      } */
      // minus first block
      yFixed = ey + t.height - 150;
    }

    const rInitial = Math.abs(ex - x) / 2 < rdef ? Math.abs(ex - x) / 2 : rdef;
    const r = Math.abs(yFixed - y) / 2 < rInitial ? Math.abs(yFixed - y) / 2 : rInitial;
    const h = Math.abs(yFixed - y) / 2 - r;
    const w = Math.abs(ex - x) - r * 2;

    const path = `
             M ${x} ${y}
             L ${x} ${y + h * yrvs}
             C  ${x} ${y + h * yrvs + r * yrvs} ${x} ${y + h * yrvs + r * yrvs} ${x + r * xrvs} ${y + h * yrvs + r * yrvs}
             L ${x + w * xrvs + r * xrvs} ${y + h * yrvs + r * yrvs}
             C ${ex}  ${y + h * yrvs + r * yrvs} ${ex}  ${y + h * yrvs + r * yrvs} ${ex} ${yFixed - h * yrvs}
             L ${ex} ${yFixed}
          `;
    return path;
  }

  const xTransl = x - (s.width / 2 + 60);
  const xrvs = ex - xTransl < 0 ? -1 : 1;
  const yrvs = ey - y < 0 ? -1 : 1;
  ey = ey + t.height - 150;
  y -= 0;
  rdef = 0;

  const hFixed = BOX_Y_SPACING + 30;

  const rInitial = Math.abs(ex - xTransl) / 2 < rdef ? Math.abs(ex - xTransl) / 2 : rdef;
  const r = Math.abs(ey - y) / 2 < rInitial ? Math.abs(ey - y) / 2 : rInitial;
  const h = Math.abs(ey - y);
  const w = Math.abs(ex - xTransl) - r * 2;
  // w=0;
  const path = `
            M ${x} ${y}
            L ${xTransl} ${y}
            C ${xTransl} ${y + (h - hFixed) * yrvs + r * yrvs} ${xTransl} ${y + (h - hFixed) * yrvs + r * yrvs} ${xTransl} ${
    y + (h - hFixed) * yrvs
  }
            L ${xTransl} ${y + (h - hFixed) * yrvs}
            C ${xTransl} ${y + (h - hFixed) * yrvs} ${xTransl} ${y + (h - hFixed) * yrvs} ${xTransl + r * xrvs} ${y + (h - hFixed) * yrvs}
            L ${xTransl + w * xrvs + r * xrvs} ${y + (h - hFixed) * yrvs}
            C ${ex}  ${y + (h - hFixed) * yrvs} ${ex}  ${y + (h - hFixed) * yrvs} ${ex} ${ey - hFixed * yrvs}
            L ${ex} ${ey}
 `;
  return path;
}

export function setDropShadowId(d: any) {
  // If it's already set, then return
  if (d.dropShadowId) return;

  // Generate drop shadow ID
  let id = `${d.id}-drop-shadow`;

  // If DOM object is available, then use UID method to generated shadow id
  // @ts-ignore
  if (typeof DOM !== "undefined") {
    // @ts-ignore
    // eslint-disable-next-line
    id = DOM.uid(d.id).id;
  }

  // Extend passed object with drop shadow ID
  Object.assign(d, {
    dropShadowId: id,
  });
}

// This function detects whether current browser is edge
export function isEdge() {
  // eslint-disable-next-line no-undef
  return window !== null && window.navigator.userAgent.includes("Edge");
}

/* Function converts rgba objects to rgba color string
  {red:110,green:150,blue:255,alpha:1}  => rgba(110,150,255,1)
*/
export function rgbaObjToColor({ red, green, blue, alpha }: { red: string; green: string; blue: string; alpha: string }) {
  return `rgba(${red},${green},${blue},${alpha})`;
}

export function initializeEnterExitUpdatePattern() {
  d3.selection.prototype.patternify = function initialize(params: any) {
    const { selector } = params;
    const elementTag = params.tag;
    const data = params.data || [selector];

    // Pattern in action
    let selection = this.selectAll(`.${selector}`).data(data, (d: any, i: number) => {
      if (typeof d === "object") {
        if (d.id) {
          return d.id;
        }
      }
      return i;
    });
    selection.exit().remove();
    selection = selection.enter().append(elementTag).merge(selection);
    selection.attr("class", selector);
    return selection;
  };
}

export function heightBetweenElements(parentData: any) {
  // const numberOfElementsMaxForUser = 0;
  const listOfHeightsToUse = [];
  const array = [parentData, parentData.children].flat(Infinity);
  // No need to go through the last row of chart
  const lastRow = Math.floor((array.length - 1) / MAX_CHILDREN);
  for (let i = 0; i < array.length; i += 1) {
    const userNecessaryHeightAccordingToTemplate = { simple: 350, omr: 350, next: 350, full: 350 };
    // const userNumberOfElements = 0;
    if (i === 0) {
      if (array[i].potentialSuccessor) {
        userNecessaryHeightAccordingToTemplate.omr += array[i].potentialSuccessor.length * 20;
        userNecessaryHeightAccordingToTemplate.full += array[i].potentialSuccessor.length * 20;
      }
      if (array[i].nextSteps) {
        userNecessaryHeightAccordingToTemplate.next += array[i].nextSteps.length * 20;
        userNecessaryHeightAccordingToTemplate.full += array[i].nextSteps.length * 20;
      }
      listOfHeightsToUse.push(userNecessaryHeightAccordingToTemplate);
    } else if (Math.floor((i - 1) / MAX_CHILDREN) <= lastRow) {
      const q = Math.floor((i - 1) / MAX_CHILDREN);
      if (q === listOfHeightsToUse.length || (q === 0 && listOfHeightsToUse.length === 1)) {
        listOfHeightsToUse.push({ simple: 350, omr: 350, next: 350, full: 350 });
      }
      if (array[i].potentialSuccessor) {
        userNecessaryHeightAccordingToTemplate.omr += array[i].potentialSuccessor.length * 20;
        userNecessaryHeightAccordingToTemplate.full += array[i].potentialSuccessor.length * 20;
      }
      if (array[i].nextSteps) {
        userNecessaryHeightAccordingToTemplate.next += array[i].nextSteps.length * 20;
        userNecessaryHeightAccordingToTemplate.full += array[i].nextSteps.length * 20;
      }
      listOfHeightsToUse[listOfHeightsToUse.length - 1].omr =
        listOfHeightsToUse[listOfHeightsToUse.length - 1].omr > userNecessaryHeightAccordingToTemplate.omr
          ? listOfHeightsToUse[listOfHeightsToUse.length - 1].omr
          : userNecessaryHeightAccordingToTemplate.omr;
      listOfHeightsToUse[listOfHeightsToUse.length - 1].next =
        listOfHeightsToUse[listOfHeightsToUse.length - 1].next > userNecessaryHeightAccordingToTemplate.next
          ? listOfHeightsToUse[listOfHeightsToUse.length - 1].next
          : userNecessaryHeightAccordingToTemplate.next;
      listOfHeightsToUse[listOfHeightsToUse.length - 1].full =
        listOfHeightsToUse[listOfHeightsToUse.length - 1].full > userNecessaryHeightAccordingToTemplate.full
          ? listOfHeightsToUse[listOfHeightsToUse.length - 1].full
          : userNecessaryHeightAccordingToTemplate.full;
    }
  }

  return listOfHeightsToUse;
}

export function parseTransform(a: any) {
  const b = {};
  // eslint-disable-next-line
  for (let i in (a = a.match(/(\w+\((\-?\d+\.?\d*e?\-?\d*,?)+\))+/g))) {
    const c = a[i].match(/[\w.-]+/g);
    // @ts-ignore
    b[c.shift()] = c;
  }
  return b;
}
