import React from "react";
import styled from "styled-components";
import colors from "assets/style/colors";
import logo from "assets/images/logo_LVMH_talent_connect.png";

const Container = styled.div`
  position: relative;
  background-color: ${colors.lightGrey};
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

const Text = styled.div`
  margin-top: 100px;
  & > div {
    font-family: "romain_reguRgText";
    font-size: 16px;
    margin: 20px 0;
  }
`;

const Message = styled.div`
  border: 1px solid ${colors.grey};
  background-color: white;
  padding: 10px 70px 40px 20px;
  width: 35%;
  display: flex;
`;

const Logo = styled.div`
  height: 50%;
  display: flex;
  align-items: flex-start;
  margin-right: 40px;
`;

const NoAccess: React.FunctionComponent = () => {
  return (
    <Container>
      <Message>
        <Logo>
          <img src={logo} alt="LVMH Talent Connect logo" width="200px" />
        </Logo>
        <Text>
          <div>Sorry, you are not authorized to access OMR Org Chart.</div>
          <div>
            If you want this authorization, or if you should already have access to this tool, please reach TalentConnect team through TC
            Help :
            <div>
              <a href="https://lvmh.service-now.com/tc">https://lvmh.service-now.com/tc</a>
            </div>
          </div>
          <div>Please select “OMR” as the Category and “OMR Org Chart” as the Subcategory.</div>
          <div>Talent Connect team wish you a wonderful day!</div>
        </Text>
      </Message>
    </Container>
  );
};

export default NoAccess;
