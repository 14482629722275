import { User, UserSearch } from "types/common.types";
import { ACTION_TYPES as ERROR_ACTION_TYPES } from "store/error/error.types";
import api from "api";
import { APIError, ERROR_TYPES, extractApiError, MESSAGE } from "utils/error.utils";
import { ChartActionTypes as ACTION, ActionTypes } from "./chart.types";

export const updateChart = (chart: User): ACTION => {
  return {
    type: ActionTypes.UPDATE_CHART,
    payload: chart,
  };
};

export const removeChart = (): ACTION => {
  return {
    type: ActionTypes.REMOVE_CHART,
  };
};

export const setRootUserAction = (user: UserSearch): ACTION => {
  return {
    type: ActionTypes.SET_ROOT_USER,
    payload: user,
  };
};

export const setRootUser = (user: UserSearch): any => async (dispatch: any) => {
  const { positionCode } = user;
  await dispatch({
    type: ActionTypes.SET_ROOT_USER,
    payload: { ...user },
  });

  await dispatch({
    type: ActionTypes.LOADING_CHART,
    payload: true,
  });

  try {
    const { data } = await api.chart.getChart(positionCode);
    await dispatch({
      type: ActionTypes.LOADING_CHART,
      payload: false,
    });

    dispatch({
      type: ActionTypes.UPDATE_CHART,
      payload: data,
    });
  } catch (e) {
    await dispatch({
      type: ERROR_ACTION_TYPES.SHOW_ERROR,
      payload: {
        type: ERROR_TYPES.API,
        details: extractApiError(e as APIError),
        msg: MESSAGE.DEFAULT,
      },
    });

    dispatch({
      type: ActionTypes.UPDATE_CHART,
      payload: undefined,
    });

    dispatch({
      type: ActionTypes.SET_ROOT_USER,
      payload: undefined,
    });

    await dispatch({
      type: ActionTypes.LOADING_CHART,
      payload: false,
    });
  }
};

export const setSearchBar = (searchBar: string): ACTION => {
  return {
    type: ActionTypes.SET_SEARCH_BAR,
    payload: searchBar,
  };
};

export const selectUserToEdit = (user: User): ACTION => {
  return {
    type: ActionTypes.SELECT_USER_EDIT,
    payload: user,
  };
};

export const updateUser = (user: User, hideForm = true): ACTION => {
  return {
    type: ActionTypes.UPDATE_USER,
    payload: { user, hideForm },
  };
};

export const expandManager = (user: User): ACTION => {
  return {
    type: ActionTypes.EXPAND_MANAGER,
    payload: user,
  };
};

export const shrinkManager = (user: User): ACTION => {
  return {
    type: ActionTypes.SHRINK_MANAGER,
    payload: user,
  };
};
