import React from "react";
import styled from "styled-components";
import { Field, useField } from "formik";
import { FormattedMessage } from "react-intl";

type LabelProps = {
  fontSize?: string;
};

const Label = styled.label`
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 6px 8px;
  box-shadow: none;
  transition: all 0.1s ease-in-out;
  border-radius: 20px;
  flex: 1;
  margin: 1px;
  line-height: 19px;
  font-size: ${(props: LabelProps) => (props.fontSize ? props.fontSize : "16px")};

  &:hover {
    cursor: pointer;
  }
`;

const Input = styled(Field)`
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;

  &:checked + ${Label} {
    background-color: black;
    color: #fff;
    box-shadow: none;
  }
`;

type ContainerProps = {
  width?: string;
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  display: flex;
  ${(props: ContainerProps) => props.width && `width: ${props.width};`}
`;

// label = translation key
type Props = {
  name: string;
  width?: string;
  fontSize?: string;
  parseBoolean?: boolean;
  handleChange?: (value: any) => any;
  values: Array<{ label: string; value: any }>;
};

const SwitchLabel: React.FC<Props> = ({ values, fontSize, name, width, handleChange, parseBoolean = true }) => {
  const [, , { setValue }] = useField({ name });

  return (
    <Container role="group" aria-labelledby={`radio-${name}`} width={width}>
      {values.map((v) => (
        <React.Fragment key={`radio-${name}-${v.value}`}>
          <Input
            type="radio"
            name={name}
            value={v.value}
            id={`radio-${name}-${v.value}`}
            onChange={(event: any) => {
              let val = event.target.value;
              if (parseBoolean) {
                val = event.target.value === "true" ? true : event.target.value === "false" ? false : event.target.value;
              }
              if (handleChange) {
                handleChange(val);
              }
              setValue(val);
            }}
          />
          <Label key={`radio-${name}-${v.value}`} htmlFor={`radio-${name}-${v.value}`} fontSize={fontSize}>
            <FormattedMessage id={v.label} />
          </Label>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default SwitchLabel;
