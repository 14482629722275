import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";

import { RootState } from "store/types";
import actions from "store/actions";
import colors from "assets/style/colors";
import Loader from "components/loader";
import { LOCAL_STORAGE } from "utils/auth.utils";

const Container = styled.div`
  position: relative;
  background-color: ${colors.lightGrey};
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Sso: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const login = () => dispatch(isAuthenticated ? actions.auth.logoutUser() : actions.auth.loginUser());

  const location = useLocation();
  const history = useHistory();
  const { search } = location;

  const parsed = qs.parse(search, { ignoreQueryPrefix: true });
  const { token, refreshPermission } = parsed;

  useEffect(() => {
    if (parsed && parsed.success && parsed.success === "false") {
      window.location.assign("/login/forbidden");
    }
    if (parsed && refreshPermission) {
      localStorage.setItem(LOCAL_STORAGE.PERMISSION_REFRESH, `${refreshPermission}`);
    }
    if (parsed && token) {
      const apiToken = `Bearer ${token}`;
      localStorage.setItem(LOCAL_STORAGE.API_TOKEN, apiToken);
      login();
    }
    window.history.replaceState({}, null, "/");
    history.push("/");
  }, []);

  return (
    <Container>
      <Loader backgroundColor="rgba(0, 0, 0, 0.6)" />
    </Container>
  );
};

export default Sso;
