import { ErrorTypeString } from "types/common.types";

export const ERROR_TYPES: { API: ErrorTypeString; INTERNAL: ErrorTypeString } = {
  API: "API",
  INTERNAL: "INTERNAL",
};

export const MESSAGE = {
  DEFAULT: "An error occurred !",
};

export type APIError = {
  response: {
    data: {
      response: any;
    };
  };
};

export const extractApiError = (e: APIError): any =>
  e && e.response && e.response.data && e.response.data.response ? e.response.data.response : undefined;
