import { ACTION_TYPES, AuthState, AuthActionTypes } from "./auth.types";

const initialState = {
  isAuthenticated: false,
};

const homeReducer = (state: AuthState = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_USER:
      return {
        ...state,
        isAuthenticated: true,
      };
    case ACTION_TYPES.LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default homeReducer;
