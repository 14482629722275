export default {
  search: {
    byTerm: "/search",
  },
  positions: {
    byId: (id: string) => `/positions/${id}`,
    refresh: "/refresh",
  },
  authentication: {
    validate: "/login/sso/validate",
  },
};
