const common = {
  "common.brand": "LVMH",
  "common.organizationalChart.button.simple": "Org chart",
  "common.organizationalChart.button.omr": "Succession Planning",
  "common.organizationalChart.button.next": "Next Step",
  "common.organizationalChart.button.full": "Full OMR",
  "common.searchBar.placeholder": "Type to search",
  "common.searchBar.submit": "Search",
  "common.loading.chart": "Please wait, your organization chart is being loaded",
  "common.error.sso": "Error occurred during authentication, please contact an administrator !",
};

export default common;
