import { connect } from "react-redux";
import { IntlProvider } from "react-intl";

import { RootState } from "store/types";

function mapStateToProps(state: RootState) {
  const { locale, messages } = state.i18n;
  return { key: locale, locale, messages, defaultLocale: "en" };
}

// @ts-ignore
const ConnectedIntlProvider = connect(mapStateToProps)(IntlProvider);

export default ConnectedIntlProvider;
