import React, { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter as Router, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { refreshToken } from "api/api";
import Layout from "layouts";
import Home from "screens/home";
import Login from "screens/login";
import Sso from "screens/sso";
import NoAccess from "screens/noAccess";
import { RootState } from "store/types";
import { actions } from "store/actions";
import Loader from "components/loader";
import { LOCAL_STORAGE } from "utils/auth.utils";

// Force scroll to top on page change
const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRouter: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  const login = () => dispatch(actions.auth.loginUser());

  useEffect(() => {
    (async function auth() {
      try {
        if (window.location && window.location.pathname !== "/login/sso" && localStorage.getItem(LOCAL_STORAGE.API_TOKEN)) {
          await refreshToken();
          login();
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <Loader backgroundColor="white" />;
  }

  if (!isAuthenticated) {
    return (
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/login/sso" component={Sso} />
          <Route exact path="/login/forbidden" component={NoAccess} />
          <Route path="/" component={Login} />
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Switch>
          <Route path="/" component={Home} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default AppRouter;
