import React from "react";
import { useFormikContext } from "formik";

import { User } from "types/common.types";
import { getInitialValues } from "./form.config";

type Props = {
  selectedUser: User;
};

const FormReset: React.FC<Props> = ({ selectedUser = {} }) => {
  const { resetForm } = useFormikContext();
  React.useEffect(() => {
    const initialValues = getInitialValues(selectedUser);

    resetForm({ values: initialValues });
  }, [selectedUser.id]);

  return <></>;
};

export default FormReset;
