import { User, UserSearch } from "types/common.types";

export enum ActionTypes {
  UPDATE_CHART = "UPDATE_CHART",
  REMOVE_CHART = "REMOVE_CHART",
  SELECT_USER_EDIT = "SELECT_USER_EDIT",
  UPDATE_USER = "UPDATE_USER",
  SET_ROOT_USER = "SET_ROOT_USER",
  EXPAND_MANAGER = "EXPAND_MANAGER",
  SHRINK_MANAGER = "SHRINK_MANAGER",
  LOADING_CHART = "LOADING_CHART",
  SET_SEARCH_BAR = "SET_SEARCH_BAR",
}

export interface ChartState {
  orgChart?: User;
  userToEdit?: User;
  rootUser?: UserSearch;
  loadingChart?: boolean;
  searchBar?: string;
}

export type ChartActionTypes =
  | {
      type: typeof ActionTypes.UPDATE_CHART;
      payload: User;
    }
  | {
      type: typeof ActionTypes.SELECT_USER_EDIT;
      payload: User;
    }
  | {
      type: typeof ActionTypes.SET_ROOT_USER;
      payload: UserSearch;
    }
  | {
      type: typeof ActionTypes.UPDATE_USER;
      payload: { user: User; hideForm: boolean };
    }
  | {
      type: typeof ActionTypes.REMOVE_CHART;
    }
  | {
      type: typeof ActionTypes.EXPAND_MANAGER;
      payload: User;
    }
  | {
      type: typeof ActionTypes.SHRINK_MANAGER;
      payload: User;
    }
  | {
      type: typeof ActionTypes.LOADING_CHART;
      payload: boolean;
    }
  | {
      type: typeof ActionTypes.SET_SEARCH_BAR;
      payload: string;
    };
