import { ACTION_TYPES, ErrorState, ErrorActionTypes } from "./error.types";

const initialState: ErrorState = {
  error: undefined,
};

const errorReducer = (state: ErrorState = initialState, action: ErrorActionTypes): ErrorState => {
  switch (action.type) {
    case ACTION_TYPES.SHOW_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default errorReducer;
