import { UaEventOptions } from "react-ga4/types/ga4";
import ReactGA from "react-ga4";

export type ChartType = "omr" | "simple" | "next" | "full";

export const GAEventkeyMap: { [key: string]: UaEventOptions } = {
  searchBarLaunched: {
    action: "search bar open",
    label: "interface interaction",
    category: "click CTA",
  },
  chartLoaded: {
    action: " chart loaded",
    label: "interface interaction",
    category: "click CTA",
  },
  orgChartConsulted: {
    action: "org chart consulted",
    label: "interface interaction",
    category: "click CTA",
  },
  orgChartExported: {
    action: "org chart exported as json",
    label: "interface interaction",
    category: "click CTA",
  },
  orgChartImported: {
    action: "org chart imported",
    label: "interface interaction",
    category: "click CTA",
  },
  successionPlanningChartConsulted: {
    action: "succession planning chart cussulted",
    label: "interface interaction",
    category: "click CTA",
  },
  nextStepChartConsulted: {
    action: "next step chart consulted",
    label: "interface interaction",
    category: "click CTA",
  },
  fullOmrConsulted: {
    action: "full omr chart consulted",
    label: "interface interaction",
    category: "click CTA",
  },
  parameterOpened: {
    action: "parameter panel opened",
    label: "interface interaction",
    category: "click CTA",
  },
  screenshot: {
    action: "screenshot",
    label: "interface interaction",
    category: "click CTA",
  },
  refocusTriggered: {
    action: "refocus triggered",
    label: "interface interaction",
    category: "click CTA",
  },
  keyPositionUpdated: {
    action: "key position updated",
    label: "panel update",
    category: "edition in position tab",
  },
  potentialSuccessorUpdated: {
    action: "potential successor updated",
    label: "panel update",
    category: "edition in position tab",
  },
  potentialSuccessorRemoved: {
    action: "potential successo removed",
    label: "panel update",
    category: "edition in position tab",
  },
  potentialSuccessorAdded: {
    action: "potential successo added",
    label: "panel update",
    category: "edition in position tab",
  },
  employeeAdd: {
    action: "employee Add",
    label: "panel update",
    category: "edition in employee tab",
  },
  employeeRemoved: {
    action: "employee removed",
    label: "panel update",
    category: "edition in employee tab",
  },
  keyContributorChanged: {
    action: "key contributor changed",
    label: "panel update",
    category: "edition in employee tab",
  },
  futurAUpdated: {
    action: "futurA updated",
    label: "panel update",
    category: "edition in employee tab",
  },
  criticalExpertUpdated: {
    action: "critical expert updated",
    label: "panel update",
    category: "edition in employee tab",
  },
  potentialUpdated: {
    action: "potential updated",
    label: "panel update",
    category: "edition in employee tab",
  },
  retentionRiskUpdated: {
    action: "retention risk updated",
    label: "panel update",
    category: "edition in employee tab",
  },
  performanceUpdated: {
    action: "performance updated",
    label: "panel update",
    category: "edition in employee tab",
  },
  nextStepAdded: {
    action: "next step added",
    label: "panel update",
    category: "edition in employee tab",
  },
  nextStepRemoved: {
    action: "next step removed",
    label: "panel update",
    category: "edition in employee tab",
  },
  teamMemberAdd: {
    action: "team member add",
    label: "panel update",
    category: "edition in team tab",
  },
  teamMemberRemoved: {
    action: "team member removed",
    label: "panel update",
    category: "edition in team tab",
  },
  teamMemberPositionTitleMoved: {
    action: "team member position title moved",
    label: "panel update",
    category: "edition in team tab",
  },
};

export const triggerEvent = (event: keyof typeof GAEventkeyMap, value?: number) => {
  ReactGA.event({ ...GAEventkeyMap[event], value });
};

export const triggerChartConsultation = (chartType: ChartType) => {
  const customTrigger: { [key in ChartType]: () => void } = {
    omr: () => triggerEvent("successionPlanningChartConsulted"),
    simple: () => triggerEvent("orgChartConsulted"),
    next: () => triggerEvent("nextStepChartConsulted"),
    full: () => triggerEvent("fullOmrConsulted"),
  };

  customTrigger[chartType]();
};
