export const ACTION_TYPES = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
};

export interface AuthState {
  isAuthenticated: boolean;
}

export type AuthActionTypes =
  | {
      type: typeof ACTION_TYPES.LOGIN_USER;
    }
  | {
      type: typeof ACTION_TYPES.LOGOUT_USER;
    };
