import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Collapse } from "react-collapse";
import styled from "styled-components";
import { Minus, Plus } from "react-feather";

import LabelGroup from "components/editPanel/ui/LabelGroup";
import InputLabel from "components/editPanel/ui/InputLabel";
import Row from "components/editPanel/ui/Row";
import Radio from "components/radio/Radio";
import Badge from "components/badge/Badge";
import SwitchWithLabel from "components/switchWithLabel/SwitchWithLabel";
import { TALENT_STATUS, PERFORMANCE, RETENTION_RISK, POTENTIAL } from "utils/form.utils";

const CollapseContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: -6px 0;
`;

type Props = {
  employeeId: number;
};

const CollapsibleEmployeeInfo: React.FC<Props> = ({ employeeId }) => {
  const intl = useIntl();

  const [isOpened, setIsOpened] = useState(false);

  return (
    <CollapseContainer>
      <Collapse isOpened={isOpened}>
        <Row align="center">
          <InputLabel minWidth="124px" textAlign="right" margin="0 24px 0 0">
            <FormattedMessage id="form.user.performance" />
          </InputLabel>
          <LabelGroup>
            <SwitchWithLabel
              fontSize="14px"
              parseBoolean={false}
              name={`employees.${employeeId}.performance`}
              values={[
                { label: "form.user.performance.NOT_MEET", value: PERFORMANCE.NOT_MEET },
                { label: "form.user.performance.PARTIALLY", value: PERFORMANCE.PARTIALLY },
                { label: "form.user.performance.ACHIEVED", value: PERFORMANCE.ACHIEVED },
                { label: "form.user.performance.EXCEEDED", value: PERFORMANCE.EXCEEDED },
                { label: "form.user.performance.OUTSTANDING", value: PERFORMANCE.OUTSTANDING },
                { label: "form.user.performance.NO", value: PERFORMANCE.NO },
              ]}
            />
          </LabelGroup>
        </Row>
        <Row align="center">
          <InputLabel minWidth="124px" textAlign="right" margin="0 24px 0 0">
            <FormattedMessage id="form.user.retRisk" />
          </InputLabel>
          <LabelGroup items={2}>
            <Radio
              name={`employees.${employeeId}.retentionRisk`}
              values={[RETENTION_RISK.LOW, RETENTION_RISK.MED, RETENTION_RISK.HIGH, RETENTION_RISK.NO]}
            />
          </LabelGroup>
        </Row>
        <Row align="center">
          <InputLabel minWidth="124px" textAlign="right" margin="0 24px 0 0">
            <FormattedMessage id="form.user.potential" />
          </InputLabel>
          <LabelGroup items={2}>
            <Radio name={`employees.${employeeId}.potential`} values={[POTENTIAL.LOW, POTENTIAL.MED, POTENTIAL.HIGH, POTENTIAL.NO]} />
          </LabelGroup>
        </Row>
        <Row align="center">
          <InputLabel minWidth="124px" textAlign="right" margin="0 24px 0 0">
            <FormattedMessage id="form.user.talentStatus" />
          </InputLabel>
          <LabelGroup items={1}>
            <Radio
              name={`employees.${employeeId}.talentStatus`}
              values={[
                TALENT_STATUS.NONE,
                TALENT_STATUS.COMPANY,
                TALENT_STATUS.CORE,
                TALENT_STATUS.GROUP,
                TALENT_STATUS.LOW,
                TALENT_STATUS.PROMISING,
                TALENT_STATUS.TOO_NEW,
              ]}
            />
          </LabelGroup>
        </Row>
        <Row align="center">
          <InputLabel minWidth="124px" textAlign="right" margin="0 24px 0 0">
            <FormattedMessage id="form.user.futurA" />
          </InputLabel>
          <LabelGroup items={3}>
            <SwitchWithLabel
              name={`employees.${employeeId}.futurA`}
              values={[
                { value: true, label: "form.user.yes" },
                { value: false, label: "form.user.no" },
              ]}
            />
          </LabelGroup>
        </Row>
        <Row align="center">
          <InputLabel minWidth="124px" textAlign="right" margin="0 24px 0 0">
            <FormattedMessage id="form.user.criticalExpert" />
          </InputLabel>
          <LabelGroup items={3}>
            <SwitchWithLabel
              name={`employees.${employeeId}.criticalExpert`}
              values={[
                { value: true, label: "form.user.yes" },
                { value: false, label: "form.user.no" },
              ]}
            />
          </LabelGroup>
        </Row>
      </Collapse>
      <Badge
        text={intl.formatMessage({ id: `form.user.employees.${isOpened ? "less" : "more"}` })}
        Logo={isOpened ? Minus : Plus}
        onClick={() => setIsOpened(!isOpened)}
        alignSelf="flex-end"
      />
    </CollapseContainer>
  );
};

export default CollapsibleEmployeeInfo;
