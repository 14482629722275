import React from "react";
import styled from "styled-components";

import colors from "assets/style/colors";

type ContainerProps = {
  alignSelf: string;
};

const Container = styled.div`
  border-radius: 20px;
  padding: 3px 13px;
  font-size: 15px;
  cursor: pointer;
  background: ${colors.borderGrey};
  display: flex;
  align-items: center;
  width: fit-content;
  & > * {
    padding: 0 3px;
  }
  ${(props: ContainerProps) => props.alignSelf && `align-self: ${props.alignSelf};`}
`;

type Props = {
  text: string;
  alignSelf: string;
  Logo: any;
  onClick: () => any;
};

const Badge = ({ text, Logo, onClick, alignSelf }: Props) => {
  return (
    <Container onClick={onClick} alignSelf={alignSelf}>
      <Logo size={18} />
      {text}
    </Container>
  );
};

export default Badge;
