import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Key, User } from "react-feather";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Grid } from "react-flexbox-grid";

import { RootState } from "store/types";
import actions from "store/actions";
import { spacing } from "assets/style/spacing";
import colors from "assets/style/colors";
import Button from "components/button/Button";
import logo from "assets/images/logo_LVMH.svg";
import Row from "components/surface/Row";
import Loader from "components/loader";
import { sleep } from "utils/dev.utils";
import * as ENV from "config/env";

const Container = styled.div`
  position: relative;
  background-color: ${colors.lightGrey};
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Paper = styled(Grid)`
  min-width: 30%;
  border: 1px solid ${colors.grey};
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: ${spacing(10)}px ${spacing(14)}px ${spacing(4)}px ${spacing(14)}px;
  & > div {
    margin-bottom: ${spacing(8)}px;
  }
`;

const Brand = styled.img`
  height: 28px;
`;

const BrandText = styled.span`
  font-family: romain_bp_headline_boldbold;
  font-size: 20px;
  line-height: 40px;
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid ${colors.grey};
  }
  &::before {
    margin-right: 1em;
  }
  &::after {
    margin-left: 1em;
  }

  margin: ${spacing(2)}px 0;
`;

const SeparatorText = styled.span`
  font-size: 20px;
  margin-top: -8px;
`;

const Error = styled.span`
  font-size: 16px;
  color: #f44336;
  font-family: LVBold;
  margin-left: 6px;
`;

interface InputProps {
  error: boolean;
}

const Input = styled(Field)`
  font-size: 16px;
  border: 2px solid ${colors.lightGrey};
  padding: ${spacing(3)}px;
  background-color: ${colors.lightGrey};
  flex: 1;
  height: 100%;
  ${(props: InputProps) => props.error && `border: 2px solid #f44336;`};
  ${(props: InputProps) => props.error && `border-radius: 4px`};
`;

const InputLabel = styled.label`
  margin: 6px 12px 6px 0;
  min-width: 30px;
  text-align: left;
`;

const Form = styled.form`
  box-sizing: border-box;
  padding: 0 ${spacing(12)}px;
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: ${spacing(8)}px;
  }
`;

const Login: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  // const login = () => dispatch(isAuthenticated ? actions.auth.logoutUser() : actions.auth.loginUser());
  // eslint-disable-next-line
  const login = () => {
    window.location.assign(ENV.SSO_URL);
  };

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = {
    // username: Yup.string().required(req),
    // password: Yup.string().required(req),
  };

  const submit = async () => {
    setLoading(true);
    try {
      const result = await sleep(100, true);
      if (result) {
        dispatch(isAuthenticated ? actions.auth.logoutUser() : actions.auth.loginUser());
      }
    } finally {
      setLoading(false);
    }
  };

  const hideForm = true;

  return (
    <Container>
      {loading && <Loader backgroundColor="rgba(0, 0, 0, 0.6)" />}
      <Paper fluid>
        <Row justify="center" align="center" padding={`0 0 ${spacing(8)}px 0;`} position="relative" direction="column">
          <Brand src={logo} alt="logo" />
          <BrandText>Organization Chart</BrandText>
        </Row>
        {!hideForm && (
          <>
            <Formik initialValues={initialValues} enableReinitialize validationSchema={Yup.object(validationSchema)} onSubmit={submit}>
              {({ handleSubmit, touched, errors }: any) => (
                <Form onSubmit={handleSubmit}>
                  <Row align="center">
                    <InputLabel>
                      <User color="black" size={28} />
                    </InputLabel>
                    <Input
                      type="text"
                      placeholder={intl.formatMessage({ id: "form.login.username" })}
                      id="username"
                      name="username"
                      error={!!touched.username && errors.username}
                    />
                    <Error>{!!touched.username && errors.username}</Error>
                  </Row>
                  <Row align="center">
                    <InputLabel>
                      <Key color="black" size={28} />
                    </InputLabel>
                    <Input
                      type="password"
                      placeholder={intl.formatMessage({ id: "form.login.password" })}
                      id="password"
                      name="password"
                      error={!!touched.password && errors.password}
                    />
                    <Error>{!!touched.password && errors.password}</Error>
                  </Row>
                  <Row justify="center">
                    <Button type="submit" value="Login with credentials" variant="black" fontSize={16} />
                  </Row>
                </Form>
              )}
            </Formik>
            <Separator>
              <SeparatorText>or</SeparatorText>
            </Separator>
          </>
        )}

        <Row justify="center">
          <Button type="button" value="Login with SSO" variant="black" fontSize={16} onClick={login} />
        </Row>
      </Paper>
    </Container>
  );
};

export default Login;
