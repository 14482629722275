import React from "react";

import Organigram from "components/organigram";
import EditPanel from "components/editPanel";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
`;

const Home: React.FunctionComponent = () => {
  return (
    <Container>
      <Organigram />
      <EditPanel />
    </Container>
  );
};

export default Home;
