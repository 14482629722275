import React from "react";
import styled from "styled-components";
import { FieldArray } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { updateUser } from "store/chart/chart.actions";
import { User } from "types/common.types";

import Row from "components/editPanel/ui/Row";
import InputLabel from "components/editPanel/ui/InputLabel";
import LabelGroup from "components/editPanel/ui/LabelGroup";
import Input from "components/editPanel/ui/Input";
import SectionTitle from "components/editPanel/ui/SectionTitle";
import SwitchWithLabel from "components/switchWithLabel/SwitchWithLabel";
import Radio from "components/radio/Radio";
import { FORM_TYPES, KEY_POSITION } from "utils/form.utils";
import { renderButtonSubForm } from "./form.config";
import CollapsibleSuccessor from "./CollapsibleSuccessor";

const Container = styled.div`
  margin-bottom: 12px;
  position: relative;
`;

const SubFormContainer = styled.div`
  padding: 0 20px 10px 20px;
  margin: 10px 0 12px 0;
  background-color: white;
  width: 100%;
  & > div {
    margin-bottom: 16px;
  }
`;

type Props = {
  values: any;
  selectedUser: User;
};

const PositionForm: React.FC<Props> = ({ values, selectedUser }) => {
  const intl = useIntl();

  return (
    <Container>
      <Row>
        <SectionTitle>
          <FormattedMessage id="form.user.id" values={{ id: values.id }} />
        </SectionTitle>
      </Row>
      <Row justify="space-between">
        <LabelGroup items={2}>
          <InputLabel>
            <FormattedMessage id="form.user.title" />
          </InputLabel>
          <Input type="text" id="title" name="title" />
        </LabelGroup>
        <LabelGroup items={2}>
          <InputLabel>
            <FormattedMessage id="form.user.keyPosition" />
          </InputLabel>
          <Radio name="keyPosition" values={[KEY_POSITION.NONE, KEY_POSITION.COMPANY, KEY_POSITION.GROUP]} />
        </LabelGroup>
      </Row>
      <Row>
        <SubFormContainer>
          <FieldArray
            name="potentialSuccessor"
            render={(arrayHelpers) => (
              <>
                <SectionTitle>
                  <FormattedMessage id="form.user.potentialSuccessors" />
                  {renderButtonSubForm(
                    intl,
                    arrayHelpers,
                    FORM_TYPES.SUCCESSOR,
                    values.potentialSuccessor.length > 0 ? values.potentialSuccessor.length + 1 : 0
                  )}
                  <LabelGroup items={2} style={{ marginLeft: "auto", textTransform: "none" }}>
                    <SwitchWithLabel
                      name="displaySuccessors"
                      values={[
                        { value: true, label: "form.user.isClosed.display" },
                        { value: false, label: "form.user.isClosed.hide" },
                      ]}
                      handleChange={(value: any) => {
                        if (selectedUser) {
                          selectedUser.displaySuccessors = value;
                          updateUser({ ...selectedUser });
                        }
                      }}
                    />
                  </LabelGroup>
                </SectionTitle>
                {values.potentialSuccessor.map((s: any, i: number) => (
                  <CollapsibleSuccessor key={i} i={i} onRemove={() => arrayHelpers.remove(i)} />
                ))}
              </>
            )}
          />
        </SubFormContainer>
      </Row>
    </Container>
  );
};

export default PositionForm;
