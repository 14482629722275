import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Collapse } from "react-collapse";
import { ChevronRight, Trash2 } from "react-feather";

import LabelGroup from "components/editPanel/ui/LabelGroup";
import InputLabel from "components/editPanel/ui/InputLabel";
import Input from "components/editPanel/ui/Input";
import Row from "components/editPanel/ui/Row";
import Radio from "components/radio/Radio";
import { READINESS } from "utils/form.utils";
import styled from "styled-components";
import colors from "assets/style/colors";

const CollapseHeader = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  & * {
    cursor: pointer;
  }
`;

type CollapseContainerProps = {
  isOpened?: boolean;
};

const Group = styled.div`
  display: flex;
  align-items: center;
  ${(props: CollapseContainerProps) => props.isOpened && `& svg { transition: all 250ms; transform: rotate(90deg); }`}
`;

const CollapseContainer = styled.div`
  background: #e9e9e9;
  border: 1px solid ${colors.borderGrey};
  padding: 6px 12px 12px 12px;
  &:hover {
    background: #f6f5f3;
  }
  ${(props: CollapseContainerProps) => props.isOpened && `background: #f6f5f3;`}
`;

type Props = {
  employeeId: number;
  stepId: number;
  onRemove: () => any;
};

const CollapsibleNextStep: React.FC<Props> = ({ employeeId, stepId, onRemove }) => {
  const [isOpened, setIsOpened] = useState(true);

  return (
    <CollapseContainer isOpened={isOpened}>
      <CollapseHeader onClick={() => setIsOpened(!isOpened)}>
        <Group isOpened={isOpened}>
          <InputLabel margin="0 48px 0 0" padding="0">
            <FormattedMessage id="form.user.nextSteps.list" values={{ id: stepId + 1 }} />
          </InputLabel>
          <ChevronRight size={24} />
        </Group>
        <Trash2 size={24} onClick={onRemove} />
      </CollapseHeader>
      <Collapse isOpened={isOpened}>
        <Row justify="space-between">
          <LabelGroup items={2}>
            <InputLabel>
              <FormattedMessage id="form.user.jobTitle" />:
            </InputLabel>
            <Input
              type="text"
              id={`employees.${employeeId}.nextSteps.${stepId}.jobTitle`}
              name={`employees.${employeeId}.nextSteps.${stepId}.jobTitle`}
            />
          </LabelGroup>
          <LabelGroup items={2}>
            <InputLabel>
              <FormattedMessage id="form.user.maison" />:
            </InputLabel>
            <Input
              type="text"
              id={`employees.${employeeId}.nextSteps.${stepId}.maison`}
              name={`employees.${employeeId}.nextSteps.${stepId}.maison`}
            />
          </LabelGroup>
        </Row>
        <LabelGroup>
          <InputLabel>
            <FormattedMessage id="form.user.readiness" />
          </InputLabel>
          <Radio name={`employees.${employeeId}.nextSteps.${stepId}.readiness`} values={[READINESS.YEAR, READINESS.MID, READINESS.LONG]} />
        </LabelGroup>
      </Collapse>
    </CollapseContainer>
  );
};

export default CollapsibleNextStep;
