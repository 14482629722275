import React from "react";
import styled from "styled-components";
import { FieldArray } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Trash2 } from "react-feather";
import { useDispatch } from "react-redux";

import { FORM_TYPES } from "utils/form.utils";
import colors from "assets/style/colors";
import Row from "components/editPanel/ui/Row";
import LabelGroup from "components/editPanel/ui/LabelGroup";
import InputLabel from "components/editPanel/ui/InputLabel";
import Input from "components/editPanel/ui/Input";
import SectionTitle from "components/editPanel/ui/SectionTitle";
import SwitchWithLabel from "components/switchWithLabel/SwitchWithLabel";
import actions from "store/actions";
import { User } from "types/common.types";
import CollapsibleNextStep from "./CollapsibleNextStep";
import { renderButtonSubForm } from "./form.config";
import CollapsibleEmployeeInfo from "./CollapsibleEmployeeInfo";
import Divider from "./ui/Divider";

const Container = styled.div`
  margin-bottom: 12px;
  position: relative;
`;

const SubFormContainer = styled.div`
  padding: 12px 20px;
  margin-bottom: 12px;
  background-color: white;
  width: 100%;
  & > div {
    margin-bottom: 12px;
  }
`;

const EmployeeLabel = styled(Row)`
  margin: 0;
  align-items: center;
  flex-grow: 1;
  min-height: 38px;
  padding-left: 10px;
  background-color: ${colors.lightGrey};
  border-bottom: 1px solid #c4c4c4;
`;

const TrashContainer = styled.div`
  align-self: center;
  cursor: pointer;
`;

type Props = {
  values: any;
  selectedUser: User;
};

const EmployeeForm: React.FC<Props> = ({ values, selectedUser }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const updateUser = (user: any) => dispatch(actions.chart.updateUser(user, false));

  return (
    <Container>
      <Row>
        <FieldArray
          name="employees"
          render={(arrayHelpers) => (
            <>
              <SectionTitle>
                <FormattedMessage id="form.user.employees" />
                {renderButtonSubForm(
                  intl,
                  arrayHelpers,
                  FORM_TYPES.EMPLOYEES,
                  values.employees.length > 0 ? values.employees.length + 1 : 0
                )}
              </SectionTitle>

              {values.employees.map((e: any, i: number) => (
                <SubFormContainer key={`employees.${i}`}>
                  <Row fullWidth align="stretch" gap="24px">
                    <EmployeeLabel>{intl.formatMessage({ id: "form.user.employee" }, { index: i + 1 })}</EmployeeLabel>
                    <TrashContainer onClick={() => arrayHelpers.remove(i)}>
                      <Trash2 />
                    </TrashContainer>
                  </Row>

                  <Row justify="space-between">
                    <LabelGroup items={2}>
                      <InputLabel>
                        <FormattedMessage id="form.user.firstName" />:
                      </InputLabel>
                      <Input type="text" id={`employees.${i}.firstName`} name={`employees.${i}.firstName`} />
                    </LabelGroup>
                    <LabelGroup items={2}>
                      <InputLabel>
                        <FormattedMessage id="form.user.lastName" />:
                      </InputLabel>
                      <Input type="text" id={`employees.${i}.lastName`} name={`employees.${i}.lastName`} />
                    </LabelGroup>
                  </Row>
                  <Row justify="space-between">
                    <LabelGroup items={2}>
                      <InputLabel>
                        <FormattedMessage id="form.user.jobTenure" />:
                      </InputLabel>
                      <Input type="text" id={`employees.${i}.jobTenure`} name={`employees.${i}.jobTenure`} />
                    </LabelGroup>
                    <LabelGroup items={2}>
                      <InputLabel>
                        <FormattedMessage id="form.user.display.omr.manager" />
                      </InputLabel>
                      <SwitchWithLabel
                        name={`employees.${i}.displayOmrInfo`}
                        values={[
                          { value: true, label: "form.user.yes" },
                          { value: false, label: "form.user.no" },
                        ]}
                        handleChange={(value: any) => {
                          if (selectedUser.employees[i]) {
                            selectedUser.employees[i].displayOmrInfo = value;
                            updateUser({ ...selectedUser });
                          }
                        }}
                      />
                    </LabelGroup>
                  </Row>
                  <Row justify="space-between">
                    <LabelGroup items={2}>
                      <InputLabel>
                        <FormattedMessage id="form.user.groupStartDate" />:
                      </InputLabel>
                      <Input type="text" id={`employees.${i}.groupStartDate`} name={`employees.${i}.groupStartDate`} />
                    </LabelGroup>
                    <LabelGroup items={2}>
                      <InputLabel>
                        <FormattedMessage id="form.user.hide.manager.photo" />
                      </InputLabel>
                      <SwitchWithLabel
                        name={`employees.${i}.hidePhoto`}
                        values={[
                          { value: true, label: "form.user.yes" },
                          { value: false, label: "form.user.no" },
                        ]}
                        handleChange={(value: any) => {
                          if (selectedUser.employees[i]) {
                            selectedUser.employees[i].hidePhoto = value;
                            updateUser({ ...selectedUser });
                          }
                        }}
                      />
                    </LabelGroup>
                  </Row>
                  <Divider />
                  <CollapsibleEmployeeInfo employeeId={i} />
                  <Divider />
                  <FieldArray
                    name={`employees.${i}.nextSteps`}
                    render={(aHelper) => (
                      <>
                        <SectionTitle>
                          <FormattedMessage id="form.user.nextSteps" />
                          {renderButtonSubForm(
                            intl,
                            aHelper,
                            FORM_TYPES.NEXT_STEPS,
                            e.nextSteps && e.nextSteps.length > 0 ? e.nextSteps.length + 1 : 0
                          )}
                          <LabelGroup items={2} style={{ marginLeft: "auto", textTransform: "none" }}>
                            <SwitchWithLabel
                              name={`employees.${i}.displayNextSteps`}
                              values={[
                                { value: true, label: "form.user.isClosed.display" },
                                { value: false, label: "form.user.isClosed.hide" },
                              ]}
                              handleChange={(value: any) => {
                                if (selectedUser.employees[i]) {
                                  selectedUser.employees[i].displayNextSteps = value;
                                  updateUser({ ...selectedUser });
                                }
                              }}
                            />
                          </LabelGroup>
                        </SectionTitle>
                        {e.nextSteps &&
                          e.nextSteps.map((n: any, j: number) => (
                            <CollapsibleNextStep key={j} employeeId={i} stepId={j} onRemove={() => aHelper.remove(j)} />
                          ))}
                      </>
                    )}
                  />
                </SubFormContainer>
              ))}
            </>
          )}
        />
      </Row>
    </Container>
  );
};

export default EmployeeForm;
